import {Button, Card, Col, Descriptions, Form, Layout, message, Row, Space, Typography} from 'antd';
import {useCallback, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import axios from '../../api/axios';
import Approvers from '../../components/Approvers';
import AddApprover from '../../components/Forms/AddApprover';
import Sidebar from '../../components/layout/Sidebar';

const {Content} = Layout;
const {Title} = Typography;
const BORROWER = '/v1/borrower/';
const CREDIT = '/credit/';
const APPROVAL = '/approvals';
const GUARANTOR = '/guarantor/';

const SingleCreditApproval = () => {
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');
  const credit_approval_value_id = window.localStorage.getItem('cv');
  const [borrower, setBorrower] = useState(null);
  const name = borrower?.first_name + '  ' + borrower?.last_name;
  const [credit_approval, setCreditApproval] = useState(null);
  const [g1, setG1] = useState(null);
  const [g2, setG2] = useState(null);
  const [add_approver, setAddApprover] = useState(false);
  const [view_approver, setViewApprover] = useState(false);
  const [approvalsData, setApprovalsData] = useState({});
  const [success, setSuccess] = useState(false);

  const fetchSigleBorrower = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrower(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrower]);
  const fetchGuarantorData = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${GUARANTOR}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setG1(response?.data[0]?.first_name + '  ' + response?.data[0]?.last_name);
      setG2(response?.data[1]?.first_name + '  ' + response?.data[1]?.last_name);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setG1, setG2]);
  const fetchSingleCreditAction = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setCreditApproval(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, credit_approval_value_id, setCreditApproval]);
  const fetchAppprovalsData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}${APPROVAL}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setApprovalsData(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, credit_approval_value_id, setApprovalsData]);
  useEffect(() => {
    fetchSigleBorrower().catch(console.error);
    fetchSingleCreditAction().catch(console.error);
    fetchGuarantorData().catch(console.error);
  }, [fetchSigleBorrower, fetchSingleCreditAction, fetchGuarantorData]);
  const guarantors = g1 + ' , ' + g2;
  const onClickAddApprover = () => {
    setAddApprover(true);
  };
  const onClickViewApprover = async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}${APPROVAL}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setApprovalsData(response?.data);
      setViewApprover(true);
    } catch (error) {
      console.log(error);
    }
  };
  const onClickComplete = () => {
    fetchAppprovalsData();
    if (approvalsData.length >= 3) {
      message.success('You have succesfully completed Credit Approval', 5);
      setSuccess(true);
    } else if (approvalsData.length < 3) {
      message.warn('You have less than 3 approvers', 5);
    }
  };
  if (!token) {
    return <Navigate to='/' />;
  }
  if (success) {
    return <Navigate to='/dashboard' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={23} offset={1}>
              <Title level={3}>Credit Approval Document</Title>
            </Col>
            <Space direction='vertical' size='large'>
              <Col span={22} offset={1}>
                <div className='number'>
                  <Row align='middle' gutter={[24, 0]}>
                    <Col span={10}>
                      {' '}
                      <Title level={5}>
                        {borrower?.first_name} {borrower?.last_name}'s Credit Approval Document
                      </Title>
                    </Col>
                    <Col span={5}></Col>
                    <Col span={4}>
                      <Button
                        type='primary'
                        onClick={onClickViewApprover}
                        style={{background: '#FE471E', width: '100%', borderColor: '#FE471E'}}
                      >
                        <p style={{color: '#ffff'}}>View Approvers</p>
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button
                        type='primary'
                        onClick={onClickAddApprover}
                        style={{background: '#ffff', width: '100%', borderColor: '#FE471E'}}
                      >
                        <p style={{color: '#000'}}>Add an Approver</p>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={22} offset={1}>
                <Form layout='vertical' className='row-col'>
                  <Row span={24}>
                    <Col span={23}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Space direction='vertical' size='large'>
                            {/* CREDIT ACTION */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Credit Action
                                  </Title>
                                </Col>
                                <Col span={24}>
                                  <Descriptions
                                    column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
                                  >
                                    <Descriptions.Item label='Credit Action'>
                                      {credit_approval?.credit_action}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </Card>
                            </Col>
                            {/* FINACIAL MODEL */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Finacial Model
                                  </Title>
                                </Col>
                                <Col span={24}>
                                  <Descriptions
                                    column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1}}
                                  >
                                    <Descriptions.Item label='Sheet Title'>
                                      {credit_approval?.financial_model_info?.sheet_tile}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Proposed Tenor'>
                                      {credit_approval?.financial_model_info?.proposed_tenor}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Interest Rate'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.internal_interest_rate
                                      }
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <Col span={24}>
                                  <Title level={5}>Asset Costs</Title>
                                </Col>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                  >
                                    <Descriptions.Item label='Total Tractor / Combine Cost (incl. HT Device)'>
                                      {credit_approval?.financial_model_info?.total_tractor_cost}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Implement Cost'>
                                      {credit_approval?.financial_model_info?.total_implement_cost}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Asset CAPEX (Tractor and Implements)'>
                                      {credit_approval?.financial_model_info?.total_asset_capex}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Subscription Cost'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.total_subscription_cost
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Asset-Related Costs'>
                                      <p className='prominent-text-credit'>
                                        {
                                          credit_approval?.financial_model_info
                                            ?.total_asset_related_costs
                                        }
                                      </p>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={24}>
                                  <Title level={5}>Set-up Costs</Title>
                                </Col>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                  >
                                    <Descriptions.Item label='Wallet Fee (Minimum Starting Balance)'>
                                      {credit_approval?.financial_model_info?.wallet_fee}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Origination Fee (2%)'>
                                      {credit_approval?.financial_model_info?.origination_fee}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Deal Value Before Downpayment'>
                                      <p className='prominent-text-credit'>
                                        {
                                          credit_approval?.financial_model_info
                                            ?.deal_value_before_downpayment
                                        }
                                      </p>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                  >
                                    <Descriptions.Item label='Down Payment Percentage'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.down_payment_percentage
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Down Payment Amount'>
                                      {credit_approval?.financial_model_info?.down_payment_amount}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Contract Value (Before Leasing Fees / Interest) '>
                                      <p className='prominent-text-credit'>
                                        {credit_approval?.financial_model_info?.contract_value}
                                      </p>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                  >
                                    <Descriptions.Item label='Internal Interest (Leasing Fees)'>
                                      {credit_approval?.financial_model_info?.internal_interest}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Repayment Amount'>
                                      <p className='prominent-text-credit'>
                                        {
                                          credit_approval?.financial_model_info
                                            ?.total_repayment_amount
                                        }
                                      </p>
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                  >
                                    <Descriptions.Item label='Hectares Required per Annum'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.hectares_required_per_annum
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Total Hectares Over the Life of the Contract'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.total_hectares_over_the_life_of_the_contract
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Repayment Rate per Hectare'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.repayment_rate_per_hectare
                                      }
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={24}>
                                  <Title level={5}>Covenants - Minimum Hectares Required</Title>
                                </Col>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1}}
                                  >
                                    <Descriptions.Item label='January'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_january
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='February'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_february
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='March'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_march
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='April'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_april
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='May'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_may
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='June'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_june
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='July'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_july
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='August'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_august
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='September'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_september
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='October'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_october
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Novemnber'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_november
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='December'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_hectares_required_december
                                      }
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <>
                                  <br></br>
                                </>
                                <Col span={24}>
                                  <Title level={5}>Covenants - Minimum Hectares Generated</Title>
                                </Col>
                                <Col span={18}>
                                  <Descriptions
                                    bordered
                                    column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1}}
                                  >
                                    <Descriptions.Item label='January'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_january
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='February'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_february
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='March'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_march
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='April'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_april
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='May'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_may
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='June'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_june
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='July'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_july
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='August'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_august
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='September'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_september
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='October'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_october
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Novemnber'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_november
                                      }
                                    </Descriptions.Item>
                                    <Descriptions.Item label='December'>
                                      {
                                        credit_approval?.financial_model_info
                                          ?.minimum_revenue_generated_december
                                      }
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </Card>
                            </Col>
                            {/* BORROWER / COOPERATIVE / COMPANY SNAPSHOT */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Borrower / Cooperative / Company Snapshot
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1}}
                                      >
                                        <Descriptions.Item label='Borrower Type'>
                                          {credit_approval?.snapshot?.borrower_type}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Borrower Name'>
                                          {name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Executive Summary'>
                                          {credit_approval?.snapshot?.executive_summary}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* CREDIT REQUEST */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Credit Request
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1}}
                                      >
                                        <Descriptions.Item label='Proposed Tenor'>
                                          {credit_approval?.credit_request?.proposed_tenor}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Maturity Date'>
                                          {credit_approval?.credit_request?.maturity_date}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Interest Rate'>
                                          {credit_approval?.credit_request?.interest_rate}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Origination Fee'>
                                          {credit_approval?.credit_request?.fee}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Underlying Collateral Description'>
                                          {
                                            credit_approval?.credit_request
                                              ?.underlying_collateral_description
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Underlying Collateral Market Value'>
                                          {
                                            credit_approval?.credit_request
                                              ?.underlying_collateral_market_value
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Down Payment / Held Liquidity Reserve'>
                                          {credit_approval?.credit_request?.down_payment}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* PROFITABILITY ANALYSIS */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Profitability Analysis
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 3, xs: 1}}
                                      ></Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* PRIMARY SOURCES OF REPAYMENT */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Primary Sources Of Repayment
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Primary Source of Repayment (SSOR)'>
                                          {credit_approval?.sources_of_repayment?.primary}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Secondary Source of Repayment (SSOR)'>
                                          {credit_approval?.sources_of_repayment?.secondary}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* HISTORICAL FINANCIAL ANALYSIS */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Historical Finacial Analysis
                                      </Title>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Revenue / Income Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='YTD Revenue/ Income'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.ytd_revenue
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Prior Calendar Year Revenue / Income'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.prior_year_revenue
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Commentary'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.revenue_commentary
                                          }
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Liquidity Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Cash on hand'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.cash_on_hand
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Commentary'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.liquidity_commentary
                                          }
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Debt / Leverage Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Current debt'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.current_debt
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Debt Service Coverage Ratio'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.debt_service_coverage_ratio
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Debt to Assets Ratio'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.debt_assets_ratio
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Pro Forma Current Debt Pro Forma'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.pro_forma_current_debt_pro_forma
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Pro Forma Debt Service Coverage Ratio (based on actuals)'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.pro_forma_debt_service_coverage_ratio
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Pro Forma Debt to Assets Ratio (based on actuals)'>
                                          {
                                            credit_approval?.historical_financial_analysis
                                              ?.pro_forma_debt_assets_ratio
                                          }
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* FINANCIAL PROJECTIONS */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Finacial Projections
                                      </Title>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Revenue / Income Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Year 1 Revenue / Income'>
                                          {credit_approval?.financial_projections?.year_1_revenue}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Average Yearly Revenue / Income'>
                                          {
                                            credit_approval?.financial_projections
                                              ?.average_yearly_revenue
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Underlying Assumptions'>
                                          {
                                            credit_approval?.financial_projections
                                              ?.underlying_assumptions
                                          }
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Liquidity Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Liquidity Requirements'>
                                          {
                                            credit_approval?.financial_projections
                                              ?.liquidity_requirements
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Liquidity Trends'>
                                          {credit_approval?.financial_projections?.liquidity_trends}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={23}>
                                      <Title level={5}>Debt / Covenant Compliance Analysis</Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Pro Forma Debt at origination'>
                                          {credit_approval?.financial_projections?.pro_foma_debt}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Payback Period'>
                                          {credit_approval?.financial_projections?.payback_period}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Debt Service Coverage Ratio Minimum Met?'>
                                          {
                                            credit_approval?.financial_projections
                                              ?.debt_service_ratio_met
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Covenant Compliance Met in all periods?'>
                                          {
                                            credit_approval?.financial_projections
                                              ?.covenant_compliance_met
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Commentary'>
                                          {credit_approval?.financial_projections?.commentary}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* RISK RATING */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Risk Rating
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Existing Risk Rating'>
                                          {credit_approval?.risk_rating?.existing_risk_rating}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Commentary'>
                                          {
                                            credit_approval?.risk_rating
                                              ?.existing_risk_rating_commentary
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Pro Forma Risk Rating'>
                                          {credit_approval?.risk_rating?.pro_forma_risk_rating}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Commentary'>
                                          {
                                            credit_approval?.risk_rating
                                              ?.pro_forma_risk_rating_commentary
                                          }
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                            {/* CREDIT FACILITY STRUCTURE */}
                            <Col span={24}>
                              <Card className='criclebox '>
                                <div className='number'>
                                  <Row align='middle' gutter={[24, 0]}>
                                    <Col span={24}>
                                      <Title level={5} style={{color: '#FE471E'}}>
                                        Credit Facility Structure
                                      </Title>
                                    </Col>
                                    <Col span={24}>
                                      <Descriptions
                                        column={{xxl: 4, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}
                                      >
                                        <Descriptions.Item label='Renter / Cooperative / Company Name(s)'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.borrower_name
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Loan / Lease Size'>
                                          {credit_approval?.credit_facility_structure?.loan_size}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Guarantors'>
                                          {guarantors}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Facility Tenor'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.facility_tenor
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Interest Rate'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.interest_rate
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Maturity Date'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.maturity_date
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Fees'>
                                          {credit_approval?.credit_facility_structure?.fees}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Loan Repayment Amount per Hectare Serviced'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.loan_repayment_amount
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Asset Description (Products / SKUs / Dealer)'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.asset_description
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Covenants'>
                                          {credit_approval?.credit_facility_structure?.covenants}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Structural Enhancements'>
                                          {
                                            credit_approval?.credit_facility_structure
                                              ?.structural_enhancements
                                          }
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Others'>
                                          {credit_approval?.credit_facility_structure?.other}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>

                            {/* Complete form */}
                            <Col span={22}>
                              <div className='number'>
                                <Row align='middle' gutter={[24, 0]}>
                                  <Col span={8} offset={10}>
                                    <Button
                                      onClick={onClickComplete}
                                      type='primary'
                                      htmlType='submit'
                                      style={{
                                        background: '#FE471E',
                                        width: '90%',
                                        borderColor: '#FE471E',
                                      }}
                                    >
                                      <p style={{color: '#ffff'}}>COMPLETE</p>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Space>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Space>
          </Row>
          <AddApprover add_approver={add_approver} setAddApprover={setAddApprover} />
          <Approvers
            setViewApprover={setViewApprover}
            view_approver={view_approver}
            approvalsData={approvalsData}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SingleCreditApproval;
