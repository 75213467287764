import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { useState } from "react";
import axios from "../../api/axios";
const BORROWER = "/v1/borrower/";
const FACILITY = "/facility/";
const OVERIDE = "/overide/";

const { Option } = Select;
const { Title } = Typography;

const JudmemtalOverride = ({ scores, fetchFacility }) => {
  const token = window.localStorage.getItem("Bearer");
  const borrower_id = window.localStorage.getItem("xd");
  const facility_risk_input_id = window.localStorage.getItem(
    "facility_risk_input_id"
  );
  const [new_facility_score, setNewFacilityScore] = useState(
    scores?.facility_score
  );
  const [judgement_overide, setJudgementalOverride] = useState("");

  const OnChangeNewFacilityScore = (e) => {
    const x = e.target.value;
    setNewFacilityScore(x);
  };
  const onChangeJudgementalOverride = (value) => {
    const x = value;
    setJudgementalOverride(x);
  };

  const handleSubmit = async () => {
    try {
      await axios.patch(
        `${BORROWER}${borrower_id}${FACILITY}${facility_risk_input_id}${OVERIDE}`,
        JSON.stringify({
          facility_score: new_facility_score,
          judgement_overide: judgement_overide,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      message.success("Facility Score Override successful", 5);
      fetchFacility();
    } catch (err) {
      message.warn("Something went wrong. Please check your data", 5);
    }
  };

  return (
    <Card className="criclebox ">
      <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
        <div className="number">
          <Row align="middle" gutter={[24, 0]}>
            <Col span={10} offset={7}>
              <Title level={4}>Facility Score Judgemental Override</Title>
            </Col>
            <Col span={7}></Col>
            <Col span={5}>
              <Form.Item
                className="username"
                name="Facility Score"
                label="Facility Score"
                onChange={OnChangeNewFacilityScore}
                value={new_facility_score}
              >
                <Input placeholder={scores?.facility_score} />
              </Form.Item>
            </Col>
            <Col span={16} offset={1}>
              <Form.Item
                className="username"
                name="Judgmental Override"
                id="Judgmental Override"
                label="Judgmental Override"
              >
                <Select
                  placeholder="Choose an item"
                  onChange={onChangeJudgementalOverride}
                >
                  <Option value="N/A - no judgmental override taken">
                    N/A - no judgmental override taken
                  </Option>
                  <Option value="FRR1: Performing - little to no likelihood of default/loss given default">
                    FRR1: Performing - little to no likelihood of default/loss
                    given default
                  </Option>
                  <Option value="FRR2: Performing -low risk of default/loss given default">
                    FRR2: Performing -low risk of default/loss given default
                  </Option>
                  <Option value="FRR 3: Performing -but increased risk">
                    FRR 3: Performing -but increased risk
                  </Option>
                  <Option value="FRR 4: Acceptable asset - but warrants closer attention than normal (probability of default 50% or more)">
                    FRR 4: Acceptable asset - but warrants closer attention than
                    normal (probability of default 50% or more)
                  </Option>
                  <Option value="FRR 5: Nonperforming -but credit mitigants exist such as collateral or guarantee">
                    FRR 5: Nonperforming -but credit mitigants exist such as
                    collateral or guarantee
                  </Option>
                  <Option value="FRR 6: Loss is inherent - but not yet quantified ">
                    FRR 6: Loss is inherent - but not yet quantified
                  </Option>
                  <Option value="FRR 7: Quantified Loss has occurred">
                    FRR 7: Quantified Loss has occurred
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={22}>
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col span={8} offset={10}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        background: "#FE471E",
                        width: "50%",
                        borderColor: "#FE471E",
                      }}
                    >
                      <p style={{ color: "#ffff" }}>SUBMIT</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  );
};

export default JudmemtalOverride;
