import {Button, Card, Col, Layout, Row, Table, Typography} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import Sidebar from '../components/layout/Sidebar';
import axios from '../api/axios';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import Moment from 'react-moment';

const {Content} = Layout;
const {Title} = Typography;
const BORROWER = '/v1/borrower/';
const TRANSACTION = '/transaction/';
const FACILITY = '/facility/';
const CREDIT = '/credit/';
const Records = () => {
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');
  const credit_approval_value_id = window.localStorage.getItem('cv');
  const transaction_value_id = window.localStorage.getItem('transaction_value_id');
  const facility_risk_input_id = window.localStorage.getItem('facility_risk_input_id');
  const [borrower, setBorrower] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [facility, setFacility] = useState(null);
  const [credits, SetCredits] = useState(null);

  const facility_columns = [
    {
      title: 'S/N',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Facility Score',
      dataIndex: 'scores',
      render: (scores) => `${scores.facility_score} `,
    },
    {
      title: 'Date',
      dataIndex: 'scores',
      render: (scores) => {
        var dateLocal = new Date(scores?.created_on);
        var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
        return <Moment format='MMMM Do YYYY, h:mm:ss a'>{newDate}</Moment>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Link to='/single-facility-risk'>
          <Button
            type='primary'
            onClick={() => {
              onClickFacilityEdit(record);
            }}
            style={{background: '#FE471E', borderColor: '#FE471E'}}
          >
            <p style={{color: '#ffff'}}>View</p>
          </Button>
        </Link>
      ),
    },
    // delete button
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type='primary'
          onClick={() => {
            deleteFacility();
          }}
          style={{background: '#FE471E', borderColor: '#FE471E'}}
        >
          <p style={{color: '#ffff'}}>Delete</p>
        </Button>
      ),
    },
  ];

  const transaction_columns = [
    {
      title: 'S/N',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Transaction Score',
      dataIndex: 'scores',
      render: (scores) => `${scores.transaction_score} `,
    },
    {
      title: 'Date',
      dataIndex: 'scores',
      render: (scores) => {
        var dateLocal = new Date(scores?.created_on);
        var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
        return <Moment format='MMMM Do YYYY, h:mm:ss a'>{newDate}</Moment>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Link to='/single-transaction'>
          <Button
            type='primary'
            onClick={() => {
              onClickTransactionEdit(record);
            }}
            style={{background: '#FE471E', borderColor: '#FE471E'}}
          >
            <p style={{color: '#ffff'}}>View</p>
          </Button>
        </Link>
      ),
    },

    // delete button
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type='primary'
          onClick={() => {
            deleteTransaction();
          }}
          style={{background: '#FE471E', borderColor: '#FE471E'}}
        >
          <p style={{color: '#ffff'}}>Delete</p>
        </Button>
      ),
    },
  ];

  // CAD
  const credit_columns = [
    {
      title: 'S/N',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Credit Action',
      dataIndex: 'credit_action',
      render: (credit_action) => `${credit_action} `,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      render: (created_on) => {
        var dateLocal = new Date(created_on);
        var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
        return <Moment format='MMMM Do YYYY, h:mm:ss a'>{newDate}</Moment>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Link to='/single-credit-approval'>
          <Button
            type='primary'
            onClick={() => {
              onClickCreditEdit(record);
            }}
            style={{background: '#FE471E', borderColor: '#FE471E'}}
          >
            <p style={{color: '#ffff'}}>View</p>
          </Button>
        </Link>
      ),
    },
    // delete button
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type='primary'
          onClick={() => {
            deleteCreditAction();
          }}
          style={{background: '#FE471E', borderColor: '#FE471E'}}
        >
          <p style={{color: '#ffff'}}>Delete</p>
        </Button>
      ),
    },
  ];

  const fetchSigleBorrower = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrower(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrower]);

  const fetchTansactions = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${TRANSACTION}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setTransactions(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setTransactions]);

  const fetchCredits = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${CREDIT}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      SetCredits(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, SetCredits]);

  const fetchFacility = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${FACILITY}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setFacility(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setFacility]);

  const deleteCreditAction = useCallback(async () => {
    try {
      await axios.delete(`${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      // then we refresh the page
      fetchCredits().catch(console.error);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, credit_approval_value_id]);

  const deleteTransaction = useCallback(async () => {
    try {
      await axios.delete(`${BORROWER}${borrower_id}${TRANSACTION}${transaction_value_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      // then we refresh the page
      fetchTansactions().catch(console.error);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, transaction_value_id]);

  // delete facility
  const deleteFacility = useCallback(async () => {
    try {
      await axios.delete(`${BORROWER}${borrower_id}${FACILITY}${facility_risk_input_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      // then we refresh the page
      fetchFacility().catch(console.error);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, facility_risk_input_id]);

  // const navigate = useNavigate()
  const onClickFacilityEdit = (record) => {
    window.localStorage.setItem('facility_risk_input_id', record?.inputs.facility_risk_input_id);
  };
  const onClickTransactionEdit = (record) => {
    window.localStorage.setItem('transaction_value_id', record?.scores.transaction_value_id);
  };
  const onClickCreditEdit = (record) => {
    window.localStorage.setItem('cv', record?.credit_approval_value_id);
  };

  useEffect(() => {
    fetchSigleBorrower().catch(console.error);
    fetchFacility().catch(console.error);
    fetchTansactions().catch(console.error);
    fetchCredits().catch(console.error);
  }, [fetchSigleBorrower, fetchFacility, fetchTansactions, fetchCredits]);
  if (!token) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={23} offset={1}>
              <Title level={3}>
                {borrower?.first_name} {borrower?.last_name}'s Records
              </Title>
            </Col>
            {/* <Space direction='vertical' size='large'> */}
            <Col span={22} offset={1}></Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Col span={24}>
                  <Title level={5} style={{color: '#FE471E'}}>
                    Transactional Scoring
                  </Title>
                </Col>
                <Table columns={transaction_columns} dataSource={transactions} pagination={false} />
              </Card>
            </Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Col span={24}>
                  <Title level={5} style={{color: '#FE471E'}}>
                    Facility Risk Rating Score
                  </Title>
                </Col>
                <Table columns={facility_columns} dataSource={facility} pagination={false} />
              </Card>
            </Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Col span={24}>
                  <Title level={5} style={{color: '#FE471E'}}>
                    Credit Approval Document
                  </Title>
                </Col>
                <Table columns={credit_columns} dataSource={credits} pagination={false} />
              </Card>
            </Col>
            {/* </Space> */}
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Records;
