import { Col } from "antd";
import React from "react";

const ProfiltabilityHeader = () => {
  return (
    <>
      <Col span={6} className="transaction_header"></Col>
      <Col span={6} className="transaction_header">
        <p>YTD </p>
      </Col>
      <Col span={6} className="transaction_header">
        <p>Prior Calendar Year</p>
      </Col>
      <Col span={6} className="transaction_header">
        <p>2 Calendar Yrs Prior</p>
      </Col>
    </>
  );
};

export default ProfiltabilityHeader;
