import {Layout, Row, Col, Typography, Button, Table, Space, Card} from 'antd';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, Navigate} from 'react-router-dom';
import axios from '../api/axios';
import Sidebar from '../components/layout/Sidebar';
import AuthContext from '../context/AuthContext';

const BIO_DATA = '/v1/borrower/';
const {Content} = Layout;
const {Title} = Typography;

const Dashboard = () => {
  const [borrowers, setBorrowers] = useState(null);
  const {setBorrower} = useContext(AuthContext);
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');

  const columns = [
    {
      title: 'S/N',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name ',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name ',
    },
    {
      title: 'Identity No',
      dataIndex: 'identity_number',
      key: 'identity_number ',
    },
    {
      title: 'Phone Number',
      dataIndex: 'primary_phone_no',
      key: 'primary_phone_no ',
    },
    {
      title: 'Email',
      dataIndex: 'primary_email',
      key: 'primary_email ',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country ',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space>
          <div>
            <Link to='/edit-borrower'>
              <Button
                type='primary'
                onClick={() => {
                  onClickBorrowerEdit(record);
                }}
                style={{background: '#FE471E', borderColor: '#FE471E'}}
              >
                <p style={{color: '#ffff'}}>View</p>
              </Button>
            </Link>
          </div>
          <div>
            <Button
              type='primary'
              onClick={() => {
                deleteBorrower();
              }}
              style={{background: '#ffff', borderColor: '#FE471E'}}
            >
              <p style={{color: '#FE471E'}}>Delete</p>
            </Button>
          </div>
        </Space>
      ),
    },
  ];

  const onClickBorrowerEdit = (record) => {
    window.localStorage.setItem('xd', record?.borrower_id);
    setBorrower({...record});
  };
  const deleteBorrower = useCallback(async () => {
    try {
      const response = await axios.delete(`${BIO_DATA}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      console.log(response);
      fetchBorrowerData().catch(console.error);
    } catch (error) {
      console.log(error);
    }
  }, [token, borrower_id]);

  const fetchBorrowerData = useCallback(async () => {
    try {
      const response = await axios.get(BIO_DATA, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      setBorrowers(response?.data.reverse());
    } catch (error) {
      console.log(error);
    }
  }, [token, setBorrowers]);

  useEffect(() => {
    fetchBorrowerData().catch(console.error);
  }, [fetchBorrowerData]);

  if (!token) {
    return <Navigate to='/' />;
  }

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={22} offset={1}>
              <Title level={3}>Onboarding Tractor Borrower</Title>
            </Col>
            <Col span={22} offset={1}>
              <Title level={5}>PAYG Customer and Contract Details</Title>
            </Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Table index columns={columns} dataSource={borrowers} />
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
