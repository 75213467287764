import { Card, Col, Form, Input, message, Modal, Row } from "antd";
import axios from "../../api/axios";

const BORROWER = "/v1/borrower/";
const GUARANTOR = "/guarantor/";

const EditGuarantor = ({
  fetchGuarantorData,
  editGuarantor,
  isEditingGuarantor,
  resetEditing,
  setBorrowersGnt,
  setEditGuarantor,
}) => {
  const token = window.localStorage.getItem("Bearer");
  const patchBorrowerData = async (data) => {
    try {
      await axios.patch(
        `${BORROWER}${data?.borrower_id}${GUARANTOR}${data?.guarantor_id}`,
        JSON.stringify({
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone_no: data?.phone_no,
          identity_number: data?.identity_number,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      message.success("You have successfully changed borrower data", 5);
      fetchGuarantorData();
    } catch (error) {
      console.log(error);
      message.warn("Somethingwent wrong.Try Again", 5);
    }
  };
  return (
    <Card className="criclebox ">
      <Modal
        title="Edit Guarantor Information"
        okText="Save"
        visible={isEditingGuarantor}
        onCancel={() => {
          resetEditing();
        }}
        onOk={() => {
          setBorrowersGnt((pre) => {
            return pre.map((record) => {
              if (record?.guarantor_id === editGuarantor?.guarantor_id) {
                return patchBorrowerData(editGuarantor);
              } else {
                return record;
              }
            });
          });
          resetEditing();
        }}
      >
        <div className="number">
          <Row align="middle" gutter={[24, 0]}>
            <Col span={11}>
              <Form.Item className="username" label="First Name">
                <Input
                  value={editGuarantor?.first_name}
                  onChange={(e) => {
                    setEditGuarantor((pre) => {
                      return { ...pre, first_name: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item className="username" label="Last Name">
                <Input
                  value={editGuarantor?.last_name}
                  onChange={(e) => {
                    setEditGuarantor((pre) => {
                      return { ...pre, last_name: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item className="username" label="Email">
                <Input
                  value={editGuarantor?.email}
                  onChange={(e) => {
                    setEditGuarantor((pre) => {
                      return { ...pre, email: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item className="username" label="Phone Number">
                <Input
                  value={editGuarantor?.phone_no}
                  onChange={(e) => {
                    setEditGuarantor((pre) => {
                      return { ...pre, phone_no: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item className="username" label="Identity Number">
                <Input
                  value={editGuarantor?.identity_number}
                  onChange={(e) => {
                    setEditGuarantor((pre) => {
                      return { ...pre, identity_number: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>
    </Card>
  );
};

export default EditGuarantor;
