import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { useState } from "react";
import axios from "../../api/axios";

const BORROWER = "/v1/borrower/";
const CREDIT = "/credit/";
const APPROVAL = "/approvals";

const { Option } = Select;

const AddApprover = ({ add_approver, setAddApprover }) => {
  const token = window.localStorage.getItem("Bearer");
  const borrower_id = window.localStorage.getItem("xd");
  const credit_approval_value_id = window.localStorage.getItem("cv");

  const [approver_state, setApproverState] = useState("");
  const [approver_date, setApproverDate] = useState("");
  const [approver_name, setApproverName] = useState("");

  const onChangeApproverState = (value) => {
    const x = value;
    setApproverState(x);
  };
  const onChangeApproverDate = (date, dateString) => {
    const x = dateString;
    setApproverDate(x);
  };
  const onChangeApproverName = (e) => {
    const x = e.target.value;
    setApproverName(x);
  };

  const handleSubmit = async () => {
    const formData = {
      state: approver_state,
      date_approved: approver_date,
      approver_name,
    };

    try {
      await axios.post(
        `${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}${APPROVAL}`,
        JSON.stringify(formData),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      message.success("You have succesfully added an approver", 5);
      setAddApprover(false);
    } catch (err) {
      message.warn("Something went wrong. Please check your data", 5);
    }
  };
  return (
    <Card className="criclebox ">
      <Modal
        title="Add Approver"
        onOk={handleSubmit}
        okText="Save"
        visible={add_approver}
        onCancel={() => {
          setAddApprover(false);
        }}
      >
        <Form layout="vertical" className="row-col">
          <div className="number">
            <Row align="middle" gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  className="username"
                  name="Approver State"
                  label="State"
                >
                  <Select
                    placeholder="Choose an Item"
                    onChange={onChangeApproverState}
                  >
                    <Option value="Approved">Approved</Option>
                    <Option value="Objection">Objection</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item className="username" name="Date" label="Date">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Choose Date"
                    onChange={onChangeApproverDate}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  className="username"
                  name="Approver Name"
                  label="Approver Name"
                  onChange={onChangeApproverName}
                  value={approver_name}
                >
                  <Input placeholder="Approver Name" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default AddApprover;
