import {Layout, Row, Col, Typography} from 'antd';
import {useState} from 'react';
import {Navigate} from 'react-router-dom';
import BioData from '../components/Forms/BioData/BioData';
import BusinessReference from '../components/Forms/BusinessReference';
import GuarantorReferenceForm from '../components/Forms/GuarantorReferenceForm';
import PersonalReferenceForm from '../components/Forms/PersonalReferenceForm';

const {Content} = Layout;
const {Title} = Typography;

const Onboarding = () => {
  const [current, setCurrent] = useState(0);
  const [success, setSuccess] = useState(false);

  if (success) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Content style={{backgroundColor: '#ffff'}}>
        <Row span={24}>
          <Col span={22} offset={1}>
            <Title level={3}>Onboarding Tractor Borrower</Title>
          </Col>
          <Col span={22} offset={1}>
            <Title level={5}>PAYG Customer and Contract Details</Title>
          </Col>
          <Col span={22} offset={1}>
            <Row gutter={[24, 0]}>
              <Col span={24} className='mb-24 '>
                <div className='number'>
                  <Row align='middle' gutter={[24, 0]}>
                    {/* Bio Data */}
                    {current === 0 && <BioData setCurrent={setCurrent} />}

                    {/* Personal Reference Data */}
                    {current === 1 && <PersonalReferenceForm setCurrent={setCurrent} />}

                    {/* Business Reference Data */}
                    {current === 2 && <BusinessReference setCurrent={setCurrent} />}

                    {/* Guarantor Reference Data */}
                    {current === 3 && (
                      <GuarantorReferenceForm setCurrent={setCurrent} setSuccess={setSuccess} />
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Onboarding;
