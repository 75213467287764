import {Col, Layout, Row, Typography} from 'antd';
import React from 'react';
import Iframe from 'react-iframe';
import {Navigate} from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';

const {Content} = Layout;
const {Title} = Typography;
const GeneralDash = () => {
  const token = window.localStorage.getItem('Bearer');
  const dash_url =
    'https://datastudio.google.com/embed/reporting/b876edb7-1575-4fdd-8c23-c1bcb3e575b5/page/p_w4gy1rxiwc';
  // const new_url = `${dash_url}${token}$`
  if (!token) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={22} offset={1}>
              <Title level={3}>Dashboard</Title>
            </Col>
            <Col span={22} offset={1}>
              <Iframe src={dash_url} width='100%' height='500%' className='iframe-style' />
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default GeneralDash;
