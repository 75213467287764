import {
  Layout,
  Typography,
  Row,
  Col,
  Card,
  Form,
  Input,
  DatePicker,
  Button,
  Select,
  Space,
  message,
} from 'antd';
import {useCallback, useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';
import axios from '../api/axios';
import ProfiltabilityHeader from '../components/ProfiltabilityHeader';
const {Content} = Layout;
const {Title} = Typography;
const {TextArea} = Input;
const {Option} = Select;
const BORROWER = '/v1/borrower/';
const CREDIT = '/credit/';

const CreditApproval = () => {
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');
  const credit_approval_value_id = window.localStorage.getItem('cv');
  const [borrower, setBorrower] = useState(null);
  const [credit_approval, setCreditApproval] = useState(null);

  const [success, setSuccess] = useState(false);

  const fetchSigleBorrower = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrower(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrower]);
  useEffect(() => {
    fetchSigleBorrower().catch(console.error);
    if (credit_approval_value_id) {
      fetchSingleCreditAction().catch(console.error);
    }
  }, [fetchSigleBorrower, credit_approval_value_id]);
  const [credit_action, setCreditAction] = useState('');
  const [financial_model_url, setFinancialModelUrl] = useState('');
  const [borrower_type, setBorrowerType] = useState('');
  const [executive_summary, setExecutiveSummary] = useState('');
  const [proposed_tenor, setProposedTenor] = useState('');
  const [maturity_date, setMaturityDate] = useState('');
  const [intrest_rate, setInterestRate] = useState('');
  const [fee, setFee] = useState('');
  const [collateral_description, setCollateralDescription] = useState('');
  const [collateral_market_value, setCollateralMarketValue] = useState('');
  const [down_payment, setDownPayment] = useState('');
  const [credit_products_ytd, setCrediProductsYTD] = useState('');
  const [credit_products_y1, setCrediProductsY1] = useState('');
  const [credit_products_y2, setCrediProductsY2] = useState('');
  const [ht_devices_ytd, setHTDeviceYTD] = useState('');
  const [ht_devices_y1, setHTDeviceY1] = useState('');
  const [ht_devices_y2, setHTDeviceY2] = useState('');
  const [ht_software_subsc_ytd, setHTSoftwareSubscYTD] = useState('');
  const [ht_software_subsc_y1, setHTSoftwareSubscY1] = useState('');
  const [ht_software_subsc_y2, setHTSoftwareSubscY2] = useState('');
  const [primary_source, setPrimarySource] = useState('');
  const [secondary_source, setSecondarySource] = useState('');
  const [kcr1, setKcr1] = useState('');
  const [kcr2, setKcr2] = useState('');
  const [kcr3, setKcr3] = useState('');
  const [ytd_revenue, setYtdRevenue] = useState('');
  const [prior_year_revenue, setPriorYearRevenue] = useState('');
  const [commetary, setCommentary] = useState('');
  const [liquidity_cash_on_hand, setLiquidityCashOnHand] = useState('');
  const [liquidity_commetary, setLiquidityCommentary] = useState('');
  const [current_debt, setCurrentDebt] = useState('');
  const [debt_service_ratio, setDebtServiceRatio] = useState('');
  const [debt_asset_ratio, setDebtAssetRatio] = useState('');
  const [current_debt_pf, setCurrentDebtPf] = useState('');
  const [debt_service_pf, setDebtServicePf] = useState('');
  const [debt_assest_pf, setDebtAssetPf] = useState('');
  const [y1_revenue, setY1Revenue] = useState('');
  const [avg_year_revenue, setAvgYearRevenue] = useState('');
  const [underlying_assumptions, setUnderlyingAssumptions] = useState('');
  const [liquidity_requirements, setLiquidityRequirements] = useState('');
  const [liquidity_trends, setLiquidityTrends] = useState('');
  const [debt_origination_pf, setDebtOriginationPf] = useState('');
  const [payback_period, setPaybackPeriod] = useState('');
  const [debt_service_ratio_f, setDebtServiceRatioF] = useState('');
  const [covenant_compliance, setCovenantCompliance] = useState('');
  const [commetary_f, setCommentaryF] = useState('');
  const [existing_risk_rating, setExistingRiskRating] = useState('');
  const [existing_risk_rating_com, setExistingRiskRatingCom] = useState('');
  const [pf_risk_rating, setPfRiskRating] = useState('');
  const [pf_risk_rating_com, setPfRiskRatingCom] = useState('');
  const [renter_name, setRenterName] = useState('');
  const [loan_size, setLoanSize] = useState('');
  const [guarantors_cfs, setGuarantorsCfs] = useState('');
  const [facility_tenor_cfs, setFacilityTenor] = useState('');
  const [maturity_date_cfs, setMaturityDateCfs] = useState('');
  const [interest_rate_cfs, setInterestRateCfs] = useState('');
  const [fee_cfs, setFeeCfs] = useState('');
  const [loan_repay_cfs, setLoanRepayCfs] = useState('');
  const [asset_description_cfs, setAssetDescriptionCfs] = useState('');
  const [covenants_cfs, setCovenanatsCfs] = useState('');
  const [structural_enhancements_cfs, setStructuralEnhancementsCfs] = useState('');
  const [other_cfs, setotherCfs] = useState('');

  const onChangeCreditAction = (value) => {
    const x = value;
    setCreditAction(x);
  };
  const onChangeFinancialModelUrl = (e) => {
    const x = e.target.value;
    setFinancialModelUrl(x);
  };
  const onChangeBorrowerType = (value) => {
    const x = value;
    setBorrowerType(x);
  };
  const onChangeExecutiveSummary = (e) => {
    const x = e.target.value;
    setExecutiveSummary(x);
  };
  const onChangeProposedTenor = (e) => {
    const x = e.target.value;
    setProposedTenor(x);
  };
  const onChangeMaturityDate = (date, dateString) => {
    const x = dateString;
    setMaturityDate(x);
  };
  const onChangeCollateralDescription = (e) => {
    const x = e.target.value;
    setCollateralDescription(x);
  };
  const onChangeCollateralMarketValue = (e) => {
    const x = e.target.value;
    setCollateralMarketValue(x);
  };
  const onChangeDownPayment = (e) => {
    const x = e.target.value;
    setDownPayment(x);
  };
  const onChangeFee = (e) => {
    const x = e.target.value;
    setFee(x);
  };
  const onChageIntrestRate = (e) => {
    const x = e.target.value;
    setInterestRate(x);
  };
  const onChangeCrediProductsYTD = (e) => {
    const x = e.target.value;
    setCrediProductsYTD(x);
  };
  const onChangeCrediProductsY1 = (e) => {
    const x = e.target.value;
    setCrediProductsY1(x);
  };
  const onChangeCrediProductsY2 = (e) => {
    const x = e.target.value;
    setCrediProductsY2(x);
  };
  const onChangeHTDeviceYTD = (e) => {
    const x = e.target.value;
    setHTDeviceYTD(x);
  };
  const onChangeHTDeviceY1 = (e) => {
    const x = e.target.value;
    setHTDeviceY1(x);
  };
  const onChangeHTDeviceY2 = (e) => {
    const x = e.target.value;
    setHTDeviceY2(x);
  };
  const onChangeHTSoftwareSubscYTD = (e) => {
    const x = e.target.value;
    setHTSoftwareSubscYTD(x);
  };
  const onChangeHTSoftwareSubscY1 = (e) => {
    const x = e.target.value;
    setHTSoftwareSubscY1(x);
  };
  const onChangeHTSoftwareSubscY2 = (e) => {
    const x = e.target.value;
    setHTSoftwareSubscY2(x);
  };
  const onChangePrimarySource = (e) => {
    const x = e.target.value;
    setPrimarySource(x);
  };
  const onChangeHTSecondarySource = (e) => {
    const x = e.target.value;
    setSecondarySource(x);
  };
  const onChangeKcr1 = (e) => {
    const x = e.target.value;
    setKcr1(x);
  };
  const onChangeKcr2 = (e) => {
    const x = e.target.value;
    setKcr2(x);
  };
  const onChangeKcr3 = (e) => {
    const x = e.target.value;
    setKcr3(x);
  };
  const onChangeYtdRevenue = (e) => {
    const x = e.target.value;
    setYtdRevenue(x);
  };
  const onChangePriorYearRevenue = (e) => {
    const x = e.target.value;
    setPriorYearRevenue(x);
  };
  const onChangeCommentary = (e) => {
    const x = e.target.value;
    setCommentary(x);
  };
  const onChangeLiquidityCashOnHand = (e) => {
    const x = e.target.value;
    setLiquidityCashOnHand(x);
  };
  const onChangeLiquidityCommentary = (e) => {
    const x = e.target.value;
    setLiquidityCommentary(x);
  };
  const onChangeCurrentDebt = (e) => {
    const x = e.target.value;
    setCurrentDebt(x);
  };
  const onChangeDebtAssetRatio = (e) => {
    const x = e.target.value;
    setDebtAssetRatio(x);
  };
  const onChangeDebtServiceRatio = (e) => {
    const x = e.target.value;
    setDebtServiceRatio(x);
  };
  const onChangeCurrentDebtPf = (e) => {
    const x = e.target.value;
    setCurrentDebtPf(x);
  };
  const onChangeDebtServicePf = (e) => {
    const x = e.target.value;
    setDebtServicePf(x);
  };
  const onChangeDebtAssetPf = (e) => {
    const x = e.target.value;
    setDebtAssetPf(x);
  };
  const onChangeY1Revenue = (e) => {
    const x = e.target.value;
    setY1Revenue(x);
  };
  const onChangeAvgYearRevenue = (e) => {
    const x = e.target.value;
    setAvgYearRevenue(x);
  };
  const onChangeUnderlyingAssumptions = (e) => {
    const x = e.target.value;
    setUnderlyingAssumptions(x);
  };
  const onChangeLiquidityRequirements = (e) => {
    const x = e.target.value;
    setLiquidityRequirements(x);
  };
  const onChangeLiquidityTrends = (e) => {
    const x = e.target.value;
    setLiquidityTrends(x);
  };
  const onChangeDebtOriginationPf = (e) => {
    const x = e.target.value;
    setDebtOriginationPf(x);
  };
  const onChangePaybackPeriod = (e) => {
    const x = e.target.value;
    setPaybackPeriod(x);
  };
  const onChangeDebtServiceRatioF = (e) => {
    const x = e.target.value;
    setDebtServiceRatioF(x);
  };
  const onChangeCovenantCompliance = (e) => {
    const x = e.target.value;
    setCovenantCompliance(x);
  };
  const onChangeCommentaryF = (e) => {
    const x = e.target.value;
    setCommentaryF(x);
  };
  const onChangeExistingRiskRating = (value) => {
    const x = value;
    setExistingRiskRating(x);
  };
  const onChangeExistingRiskRatingCom = (e) => {
    const x = e.target.value;
    setExistingRiskRatingCom(x);
  };
  const onChangePfRiskRating = (value) => {
    const x = value;
    setPfRiskRating(x);
  };
  const onChangePfRiskRatingCom = (e) => {
    const x = e.target.value;
    setPfRiskRatingCom(x);
  };
  const onChangeRenterName = (e) => {
    const x = e.target.value;
    setRenterName(x);
  };
  const onChangeLoanSize = (e) => {
    const x = e.target.value;
    setLoanSize(x);
  };
  const onChangeGuarantorsCfs = (e) => {
    const x = e.target.value;
    setGuarantorsCfs(x);
  };
  const onChangeFacilityTenor = (e) => {
    const x = e.target.value;
    setFacilityTenor(x);
  };
  const onChangeMaturityDateCfs = (e) => {
    const x = e.target.value;
    setMaturityDateCfs(x);
  };
  const onChangeInterestRateCfs = (e) => {
    const x = e.target.value;
    setInterestRateCfs(x);
  };
  const onChangeFeeCfs = (e) => {
    const x = e.target.value;
    setFeeCfs(x);
  };
  const onChangeLoanRepayCfs = (e) => {
    const x = e.target.value;
    setLoanRepayCfs(x);
  };
  const onChangeAssetDescriptionCfs = (e) => {
    const x = e.target.value;
    setAssetDescriptionCfs(x);
  };
  const onChangeCovenanatsCfs = (e) => {
    const x = e.target.value;
    setCovenanatsCfs(x);
  };
  const onChangeStructuralEnhancementsCfs = (e) => {
    const x = e.target.value;
    setStructuralEnhancementsCfs(x);
  };
  const onChangeotherCfs = (e) => {
    const x = e.target.value;
    setotherCfs(x);
  };

  console.log(credit_approval);
  useEffect(() => {
    if (credit_approval) {
      setFinancialModelUrl(credit_approval.financial_model_url);
      setCreditAction(credit_approval.credit_action);
    }
  }, [credit_approval]);
  const form_data = {
    credit_action: credit_action,
    financial_model_url,
    snapshot: {borrower_type, executive_summary},
    credit_request: {
      proposed_tenor,
      maturity_date,
      interest_rate: intrest_rate,
      fee,
      underlying_collateral_description: collateral_description,
      underlying_collateral_market_value: collateral_market_value,
      down_payment,
    },
    profitability_analysis: {
      credit_products_ytd,
      credit_products_prior_year: credit_products_y1,
      credit_products_2_year: credit_products_y2,
      ht_device_products_ytd: ht_devices_ytd,
      ht_device_products_prior_year: ht_devices_y1,
      ht_device_products_2_year: ht_devices_y2,
      ht_software_sub_products_ytd: ht_software_subsc_ytd,
      ht_software_sub_products_prior_year: ht_software_subsc_y1,
      ht_software_sub_products_2_year: ht_software_subsc_y2,
    },
    sources_of_repayment: {primary: primary_source, secondary: secondary_source},
    key_credit_risks: {kcr1, kcr2, kcr3},
    historical_financial_analysis: {
      ytd_revenue,
      prior_year_revenue,
      revenue_commentary: commetary,
      cash_on_hand: liquidity_cash_on_hand,
      liquidity_commentary: liquidity_commetary,
      current_debt,
      debt_service_coverage_ratio: debt_service_ratio,
      debt_assets_ratio: debt_asset_ratio,
      pro_forma_current_debt_pro_forma: current_debt_pf,
      pro_forma_debt_service_coverage_ratio: debt_service_pf,
      pro_forma_debt_assets_ratio: debt_assest_pf,
    },
    financial_projections: {
      year_1_revenue: y1_revenue,
      average_yearly_revenue: avg_year_revenue,
      underlying_assumptions,
      liquidity_requirements,
      liquidity_trends,
      pro_foma_debt: debt_origination_pf,
      payback_period,
      debt_service_ratio_met: debt_service_ratio_f,
      covenant_compliance_met: covenant_compliance,
      commentary: commetary_f,
    },
    risk_rating: {
      existing_risk_rating,
      existing_risk_rating_commentary: existing_risk_rating_com,
      pro_forma_risk_rating: pf_risk_rating,
      pro_forma_risk_rating_commentary: pf_risk_rating_com,
    },
    credit_facility_structure: {
      borrower_name: renter_name,
      loan_size,
      facility_tenor: facility_tenor_cfs,
      maturity_date: maturity_date_cfs,
      interest_rate: interest_rate_cfs,
      loan_repayment_amount: loan_repay_cfs,
      fees: fee_cfs,
      asset_description: asset_description_cfs,
      covenants: covenants_cfs,
      structural_enhancements: structural_enhancements_cfs,
      other: other_cfs,
    },
  };
  const fetchSingleCreditAction = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${CREDIT}${credit_approval_value_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      // console.log(response?.data);
      setCreditApproval(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, credit_approval_value_id, setCreditApproval]);

  const handleSubmit = async () => {
    console.log(form_data);
    try {
      const response = await axios.post(
        `${BORROWER}${borrower_id}${CREDIT}`,
        JSON.stringify(form_data),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const credit_approval_value_id = response?.data?.credit_approval_value_id;
      window.localStorage.setItem('cv', credit_approval_value_id);
      message.success('You have succesfully completed credit approval', 5);
      setSuccess(true);
    } catch (err) {
      message.warn('Something went wrong. Please check your data', 5);
    }
  };
  if (!token) {
    return <Navigate to='/' />;
  }
  if (success) {
    return <Navigate to='/single-credit-approval' />;
  }
  return (
    <Layout>
      <Sidebar />
      {/* <Layout> */}
      <Content style={{backgroundColor: '#ffff'}}>
        <Row span={24}>
          <Col span={23} offset={1}>
            <Title level={3}>Credit Approval Document</Title>
          </Col>
          <Col span={23} offset={1}>
            <Title level={5}>
              {borrower?.first_name} {borrower?.last_name}'s Credit Approval Document
            </Title>
          </Col>
          <Col span={23} offset={1}>
            <Form layout='vertical' className='row-col' onFinish={handleSubmit}>
              <Row span={24}>
                <Col span={23}>
                  <div className='number'>
                    <Row align='middle' gutter={[24, 0]}>
                      <Space direction='vertical' size='large'>
                        <Col span={24}>
                          <div className='number'>
                            <Row align='middle' gutter={[24, 0]}>
                              {/* CREDIT ACTION */}
                              <Col span={12}>
                                <Card className='criclebox '>
                                  <Col span={24}>
                                    <Title level={5} style={{color: '#FE471E'}}>
                                      Credit Action
                                    </Title>
                                  </Col>
                                  <Col span={16}>
                                    <Form.Item
                                      className='username'
                                      name='Credit Action '
                                      label='Credit Action'
                                    >
                                      <Select
                                        placeholder='Choose an item.'
                                        style={{width: '100%'}}
                                        onChange={onChangeCreditAction}
                                      >
                                        <Option value='New origination'>New origination</Option>
                                        <Option value='Facility Amendment'>
                                          Facility Amendment
                                        </Option>
                                        <Option value='Renewal / Extension'>
                                          Renewal / Extension
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Card>
                              </Col>
                              {/* FINACIAL MODEL */}
                              <Col span={12}>
                                <Card className='criclebox '>
                                  <Col span={24}>
                                    <Title level={5} style={{color: '#FE471E'}}>
                                      Finacial Model Url
                                    </Title>
                                  </Col>
                                  <Col span={20}>
                                    <Form.Item
                                      className='username'
                                      name='Finacial Model Url'
                                      label='Finacial Model Url'
                                      onChange={onChangeFinancialModelUrl}
                                      value={financial_model_url}
                                      rules={[{type: 'url'}]}
                                    >
                                      <Input placeholder='Finacial Model Url' />
                                    </Form.Item>
                                  </Col>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        {/* BORROWER / COOPERATIVE / COMPANY SNAPSHOT */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Borrower / Cooperative / Company Snapshot
                                  </Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Borrower Type'
                                    label='Borrower Type'
                                  >
                                    <Select
                                      placeholder='Choose Borrower Type'
                                      onChange={onChangeBorrowerType}
                                    >
                                      <Option value='Individual'>Individual</Option>
                                      <Option value='Cooperative'>Cooperative</Option>
                                      <Option value='Other'>Other (explanation below)</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={16}></Col>
                                <Col span={20}>
                                  <Form.Item
                                    className='username'
                                    name='Executive Summary'
                                    label='Executive Summary'
                                    onChange={onChangeExecutiveSummary}
                                    value={executive_summary}
                                  >
                                    <TextArea
                                      style={{height: 250}}
                                      placeholder='Executive Summary'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* CREDIT REQUEST */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Credit Request
                                  </Title>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Proposed Tenor'
                                    label='Proposed Tenor'
                                    onChange={onChangeProposedTenor}
                                    value={proposed_tenor}
                                  >
                                    <Input placeholder='Proposed Tenor' />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Proposed Tenor Maturity Date'
                                    label='Maturity Date'
                                    onChange={onChangeProposedTenor}
                                    value={proposed_tenor}
                                  >
                                    <DatePicker
                                      style={{width: '100%'}}
                                      placeholder='Select Maturity Date'
                                      onChange={onChangeMaturityDate}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Interest Rate'
                                    label='Interest Rate'
                                    onChange={onChageIntrestRate}
                                    value={intrest_rate}
                                  >
                                    <Input placeholder='Interest Rate' />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Fees (if applicable)'
                                    label='Fees (if applicable)'
                                    onChange={onChangeFee}
                                    value={fee}
                                  >
                                    <Input placeholder='Fees (if applicable)' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Underlying Collateral Description'
                                    label='Underlying Collateral Description'
                                    onChange={onChangeCollateralDescription}
                                    value={collateral_description}
                                  >
                                    <Input placeholder='Underlying Collateral Description' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Underlying Collateral Market Value'
                                    label='Underlying Collateral Market Value'
                                    onChange={onChangeCollateralMarketValue}
                                    value={collateral_market_value}
                                  >
                                    <Input placeholder='Underlying Collateral Market Value' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Down Payment / Held Liquidity Reserve'
                                    label='Down Payment / Held Liquidity Reserve'
                                    onChange={onChangeDownPayment}
                                    value={down_payment}
                                  >
                                    <Input placeholder='Down Payment / Held Liquidity Reserve' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* PROFITABILITY ANALYSIS */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Profitability Analysis
                                  </Title>
                                </Col>
                                <ProfiltabilityHeader />
                                {/* Credit Products */}
                                <Col span={6} className='transaction_values'>
                                  <p>Credit Products</p>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Credit Products TYD'
                                    onChange={onChangeCrediProductsYTD}
                                    value={credit_products_ytd}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Credit Products Y1'
                                    onChange={onChangeCrediProductsY1}
                                    value={credit_products_y1}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='Credit Products Y2'
                                    onChange={onChangeCrediProductsY2}
                                    value={credit_products_y2}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                {/* HT Device  */}
                                <Col span={6} className='transaction_values'>
                                  <p>HT Device </p>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Device  TYD'
                                    onChange={onChangeHTDeviceYTD}
                                    value={ht_devices_ytd}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Device Y1'
                                    onChange={onChangeHTDeviceY1}
                                    value={ht_devices_y1}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Device Y2'
                                    onChange={onChangeHTDeviceY2}
                                    value={ht_devices_y2}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                {/* HT Software Subsc  */}
                                <Col span={6} className='transaction_values'>
                                  <p>HT Software Subsc </p>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Software Subsc TYD'
                                    onChange={onChangeHTSoftwareSubscYTD}
                                    value={ht_software_subsc_ytd}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Software Subsc Y1'
                                    onChange={onChangeHTSoftwareSubscY1}
                                    value={ht_software_subsc_y1}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    className='username'
                                    name='HT Software Subsc Y2'
                                    onChange={onChangeHTSoftwareSubscY2}
                                    value={ht_software_subsc_y2}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* PRIMARY SOURCES OF REPAYMENT */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Primary Sources Of Repayment
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Primary Source of Repayment (SSOR)'
                                    label='Primary Source of Repayment (SSOR)'
                                    onChange={onChangePrimarySource}
                                    value={primary_source}
                                  >
                                    <TextArea
                                      style={{height: 150}}
                                      placeholder='Primary Source of Repayment (SSOR)'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Secondary Source of Repayment (SSOR)'
                                    label='Secondary Source of Repayment (SSOR)'
                                    onChange={onChangeHTSecondarySource}
                                    value={secondary_source}
                                  >
                                    <TextArea
                                      style={{height: 150}}
                                      placeholder='Secondary Source of Repayment (SSOR)'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* KEY CREDIT RISKS  */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Key Credit Risks
                                  </Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='KCR1'
                                    label='KCR1'
                                    onChange={onChangeKcr1}
                                    value={kcr1}
                                  >
                                    <TextArea
                                      showCount
                                      maxLength={250}
                                      style={{height: 200}}
                                      placeholder='KCR1'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='KCR2'
                                    label='KCR2'
                                    onChange={onChangeKcr2}
                                    value={kcr2}
                                  >
                                    <TextArea style={{height: 200}} placeholder='KCR2' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='KCR3'
                                    label='KCR3'
                                    onChange={onChangeKcr3}
                                    value={kcr3}
                                  >
                                    <TextArea style={{height: 200}} placeholder='KCR3' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* HISTORICAL FINANCIAL ANALYSIS */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Historical Finacial Analysis
                                  </Title>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Revenue / Income Analysis</Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='YTD Revenue/ Income'
                                    label='YTD Revenue/ Income'
                                    onChange={onChangeYtdRevenue}
                                    value={ytd_revenue}
                                  >
                                    <Input placeholder='YTD Revenue/ Income' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Prior Calendar Year Revenue / Income'
                                    label='Prior Calendar Year Revenue / Income'
                                    onChange={onChangePriorYearRevenue}
                                    value={prior_year_revenue}
                                  >
                                    <Input placeholder='Prior Calendar Year Revenue / Income' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Revenue Commentary'
                                    label='Commentary'
                                    onChange={onChangeCommentary}
                                    value={commetary}
                                  >
                                    <Input placeholder='Commentary' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Liquidity Analysis</Title>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Cash on hand'
                                    label='Cash on hand'
                                    onChange={onChangeLiquidityCashOnHand}
                                    value={liquidity_cash_on_hand}
                                  >
                                    <Input placeholder='Cash on hand' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Cash on hand Commentary'
                                    label='Commentary'
                                    onChange={onChangeLiquidityCommentary}
                                    value={liquidity_commetary}
                                  >
                                    <Input placeholder='Commentary' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Debt / Leverage Analysis</Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Current debt'
                                    label='Current debt'
                                    onChange={onChangeCurrentDebt}
                                    value={current_debt}
                                  >
                                    <Input placeholder='Current debt' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Debt Service Coverage Ratio'
                                    label='Debt Service Coverage Ratio'
                                    onChange={onChangeDebtServiceRatio}
                                    value={debt_service_ratio}
                                  >
                                    <Input placeholder='Debt Service Coverage Ratio' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Debt to Assets Ratio'
                                    label='Debt to Assets Ratio'
                                    onChange={onChangeDebtAssetRatio}
                                    value={debt_asset_ratio}
                                  >
                                    <Input placeholder='Debt to Assets Ratio' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Pro Forma Current Debt Pro Forma'
                                    label='Pro Forma Current Debt Pro Forma'
                                    onChange={onChangeCurrentDebtPf}
                                    value={current_debt_pf}
                                  >
                                    <Input placeholder='Pro Forma Current Debt Pro Forma' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Pro Forma Debt Service Coverage Ratio (based on actuals)'
                                    label='Pro Forma Debt Service Coverage Ratio (based on actuals)'
                                    onChange={onChangeDebtServicePf}
                                    value={debt_service_pf}
                                  >
                                    <Input placeholder='Pro Forma Debt Service Coverage Ratio (based on actuals)' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Pro Forma Debt to Assets Ratio (based on actuals)'
                                    label='Pro Forma Debt to Assets Ratio (based on actuals)'
                                    onChange={onChangeDebtAssetPf}
                                    value={debt_assest_pf}
                                  >
                                    <Input placeholder='Pro Forma Debt to Assets Ratio (based on actuals)' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* FINANCIAL PROJECTIONS */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Finacial Projections
                                  </Title>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Revenue / Income Analysis</Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Year Revenue/ Income'
                                    label='Year 1 Revenue/ Income'
                                    onChange={onChangeY1Revenue}
                                    value={y1_revenue}
                                  >
                                    <Input placeholder='Year 1 Revenue/ Income' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Average Yearly Revenue / Income'
                                    label='Average Yearly Revenue / Income'
                                    onChange={onChangeAvgYearRevenue}
                                    value={avg_year_revenue}
                                  >
                                    <Input placeholder='Average Yearly Revenue / Income' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Underlying Assumptions'
                                    label='Underlying Assumptions'
                                    onChange={onChangeUnderlyingAssumptions}
                                    value={underlying_assumptions}
                                  >
                                    <Input placeholder='Underlying Assumptions' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Liquidity Analysis</Title>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Liquidity Requirements'
                                    label='Liquidity Requirements'
                                    onChange={onChangeLiquidityRequirements}
                                    value={liquidity_requirements}
                                  >
                                    <Input placeholder='Liquidity Requirements' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Liquidity Trends'
                                    label='Liquidity Trends'
                                    onChange={onChangeLiquidityTrends}
                                    value={liquidity_trends}
                                  >
                                    <Input placeholder='Liquidity Trends' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                  <Title level={5}>Debt / Covenant Compliance Analysis</Title>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Pro Forma Debt at origination'
                                    label='Pro Forma Debt at origination'
                                    onChange={onChangeDebtOriginationPf}
                                    value={debt_origination_pf}
                                  >
                                    <Input placeholder='Pro Forma Debt at origination' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Payback Period'
                                    label='Payback Period'
                                    onChange={onChangePaybackPeriod}
                                    value={payback_period}
                                  >
                                    <Input placeholder='Payback Period' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Debt Service Coverage Ratio Minimum Met?'
                                    label='Debt Service Coverage Ratio Minimum Met?'
                                    onChange={onChangeDebtServiceRatioF}
                                    value={debt_service_ratio_f}
                                  >
                                    <Input placeholder='Debt Service Coverage Ratio Minimum Met?' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Covenant Compliance Met'
                                    label='Covenant Compliance Met in all periods?'
                                    onChange={onChangeCovenantCompliance}
                                    value={covenant_compliance}
                                  >
                                    <Input placeholder='Covenant Compliance Met in all periods?' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Covenant Compliance Commentary'
                                    label='Commentary'
                                    onChange={onChangeCommentaryF}
                                    value={commetary_f}
                                  >
                                    <Input placeholder='Commentary' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>

                        {/* RISK RATING */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Risk Rating
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Existing Risk Rating'
                                    label='Existing Risk Rating'
                                  >
                                    <Select
                                      placeholder='Choose an item'
                                      onChange={onChangeExistingRiskRating}
                                    >
                                      <Option value='1'>
                                        1 - Performing, little to no likelihood of default/loss
                                        given default
                                      </Option>
                                      <Option value='2'>
                                        2 - Performing, low risk of default/loss given default
                                      </Option>
                                      <Option value='3'>
                                        3 - Performing, but increased risk given certain factors
                                      </Option>
                                      <Option value='4'>
                                        4 - Acceptable asset, but warrants closer attention than
                                        normal (probability of default 50% or more)
                                      </Option>
                                      <Option value='5'>
                                        5 - Nonperforming, but credit mitigants exist such as
                                        collateral or guarantee
                                      </Option>
                                      <Option value='6'>
                                        6 - Loss is inherent, but not yet quantified
                                      </Option>
                                      <Option value='7'>7 - Quantified Loss has occurred</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name=' Existing Risk Commentary'
                                    label='Commentary'
                                    onChange={onChangeExistingRiskRatingCom}
                                    value={existing_risk_rating_com}
                                  >
                                    <TextArea placeholder='Existing Risk Commentary' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Pro Forma Risk Rating'
                                    label='Pro Forma Risk Rating'
                                  >
                                    <Select
                                      placeholder='Choose an item'
                                      onChange={onChangePfRiskRating}
                                    >
                                      <Option value='1'>
                                        1 - Performing, little to no likelihood of default/loss
                                        given default
                                      </Option>
                                      <Option value='2'>
                                        2 - Performing, low risk of default/loss given default
                                      </Option>
                                      <Option value='3'>
                                        3 - Performing, but increased risk given certain factors
                                      </Option>
                                      <Option value='4'>
                                        4 - Acceptable asset, but warrants closer attention than
                                        normal (probability of default 50% or more)
                                      </Option>
                                      <Option value='5'>
                                        5 - Nonperforming, but credit mitigants exist such as
                                        collateral or guarantee
                                      </Option>
                                      <Option value='6'>
                                        6 - Loss is inherent, but not yet quantified
                                      </Option>
                                      <Option value='7'>7 - Quantified Loss has occurred</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Risk 2 Commentary'
                                    label='Commentary'
                                    onChange={onChangePfRiskRatingCom}
                                    value={pf_risk_rating_com}
                                  >
                                    <TextArea placeholder='Commentary' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>

                        {/* CREDIT FACILITY STRUCTURE */}
                        <Col span={24}>
                          <Card className='criclebox '>
                            <div className='number'>
                              <Row align='middle' gutter={[24, 0]}>
                                <Col span={24}>
                                  <Title level={5} style={{color: '#FE471E'}}>
                                    Credit Facility Structure
                                  </Title>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Renter / Cooperative / Company Name(s) '
                                    label='Renter / Cooperative / Company Name(s)'
                                    onChange={onChangeRenterName}
                                    value={renter_name}
                                  >
                                    <Input placeholder='Renter / Cooperative / Company Name(s)' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Loan / Lease Size '
                                    label='Loan / Lease Size '
                                    onChange={onChangeLoanSize}
                                    value={loan_size}
                                  >
                                    <Input placeholder='Loan / Lease Size' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Facility Tenor'
                                    label='Facility Tenor'
                                    onChange={onChangeFacilityTenor}
                                    value={facility_tenor_cfs}
                                  >
                                    <Input placeholder='Facility Tenor' />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='Maturity Date'
                                    label='Maturity Date'
                                    onChange={onChangeMaturityDateCfs}
                                    value={maturity_date_cfs}
                                  >
                                    <DatePicker
                                      style={{width: '100%'}}
                                      placeholder='Maturity Date'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    className='username'
                                    name='CF Interest Rate'
                                    label='Interest Rate'
                                    onChange={onChangeInterestRateCfs}
                                    value={interest_rate_cfs}
                                  >
                                    <Input placeholder='Interest Rate' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Loan Repayment Amount per Hectare Serviced'
                                    label='Loan Repayment Amount per Hectare Serviced'
                                    onChange={onChangeLoanRepayCfs}
                                    value={loan_repay_cfs}
                                  >
                                    <Input placeholder='Loan Repayment Amount per Hectare Serviced' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Fees'
                                    label='Fees'
                                    onChange={onChangeFeeCfs}
                                    value={fee_cfs}
                                  >
                                    <Input placeholder='Fees' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Asset Description (Products / SKUs / Dealer)'
                                    label='Asset Description (Products / SKUs / Dealer)'
                                    onChange={onChangeAssetDescriptionCfs}
                                    value={asset_description_cfs}
                                  >
                                    <TextArea
                                      style={{height: 300}}
                                      placeholder='Asset Description (Products / SKUs / Dealer)'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Covenants cfd'
                                    label='Covenants'
                                    onChange={onChangeCovenanatsCfs}
                                    value={covenants_cfs}
                                  >
                                    <TextArea style={{height: 300}} placeholder='Covenants' />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Structural Enhancements'
                                    label='Structural Enhancements'
                                    onChange={onChangeStructuralEnhancementsCfs}
                                    value={structural_enhancements_cfs}
                                  >
                                    <TextArea
                                      style={{height: 300}}
                                      placeholder='Structural Enhancements'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className='username'
                                    name='Other'
                                    label='Other'
                                    onChange={onChangeotherCfs}
                                    value={other_cfs}
                                  >
                                    <TextArea style={{height: 300}} placeholder='Other' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                        {/* Complete form */}
                        <Col span={22}>
                          <div className='number'>
                            <Row align='middle' gutter={[24, 0]}>
                              <Col span={8} offset={10}>
                                <Button
                                  type='primary'
                                  htmlType='submit'
                                  style={{
                                    background: '#FE471E',
                                    width: '90%',
                                    borderColor: '#FE471E',
                                  }}
                                >
                                  <p style={{color: '#ffff'}}>COMPLETE</p>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Space>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Content>
      {/* </Layout> */}
    </Layout>
  );
};

export default CreditApproval;
