import {
  Row,
  Col,
  DatePicker,
  Input,
  Form,
  Select,
  Button,
  Card,
  message,
  Upload,
} from "antd";
import { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import axios from "../../../api/axios";

const ADD_BIO_DATA = "/v1/borrower/";
const { Option } = Select;

const BioData = ({ setCurrent }) => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [date_of_birth, SetDateOfBirth] = useState("");
  const [identity_number, setIdentityNumber] = useState("");
  const [identity_number_link, setIdentityNumberLink] = useState();
  const [tax_number, setTaxNumber] = useState("");
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [city_region, setCityRegion] = useState("");
  const [physical_address, setPhysicalAddress] = useState("");
  const [postal_address, setPostalAddress] = useState("");
  const [primary_email, setPrimaryEmail] = useState("");
  const [secondary_email, setSecondaryEmail] = useState("");
  const [primary_phone_no, setPrimaryPhoneNo] = useState("");
  const [secondary_phone_no, setSecondaryPhoneNo] = useState("");

  const onChangeFirstName = (e) => {
    const first_name = e.target.value;
    setFirstName(first_name);
  };
  const onChangeLastName = (e) => {
    const last_name = e.target.value;
    setLastName(last_name);
  };
  const onChangeGender = (value) => {
    const gender = value;
    setGender(gender);
  };
  const onChangeIdNo = (e) => {
    const identity_number = e.target.value;
    setIdentityNumber(identity_number);
  };
  const onChangeIdNoLink = (e) => {
    const identity_number_link = e.target.files[0];
    setIdentityNumberLink(identity_number_link);
  };
  const onChangeDateOfBirth = (date, dateString) => {
    const date_of_birth = dateString;
    SetDateOfBirth(date_of_birth);
  };
  const onChangeTaxNumber = (e) => {
    const tax_number = e.target.value;
    setTaxNumber(tax_number);
  };
  const onChangePhysicalAddress = (e) => {
    const physical_address = e.target.value;
    setPhysicalAddress(physical_address);
  };
  const onChangePostalAddress = (e) => {
    const postal_address = e.target.value;
    setPostalAddress(postal_address);
  };
  const onChangeNationality = (e) => {
    const nationality = e.target.value;
    setNationality(nationality);
  };
  const onChangeCityRegion = (e) => {
    const city_region = e.target.value;
    setCityRegion(city_region);
  };
  const onChangeCountry = (e) => {
    const country = e.target.value;
    setCountry(country);
  };
  const onChangePrimaryEmail = (e) => {
    const primary_email = e.target.value;
    setPrimaryEmail(primary_email);
  };
  const onChangeSecondaryEmail = (e) => {
    const secondary_email = e.target.value;
    setSecondaryEmail(secondary_email);
  };
  const onChangePrimaryPhoneNo = (e) => {
    const primary_phone_no = e.target.value;
    setPrimaryPhoneNo(primary_phone_no);
  };
  const onChangeSecondaryPhoneNo = (e) => {
    const secondary_phone_no = e.target.value;
    setSecondaryPhoneNo(secondary_phone_no);
  };

  const form_data = {
    first_name,
    last_name,
    gender,
    identity_number,
    date_of_birth,
    tax_number,
    physical_address,
    postal_address,
    nationality,
    city_region,
    country,
    primary_email,
    secondary_email,
    primary_phone_no,
    secondary_phone_no,
  };

  const handleSubmit = async () => {
    console.log("bio data::", form_data, "files id", identity_number_link);
    const fmData = {
      borrower: JSON.stringify(form_data),
      id_file: identity_number_link,
    };
    console.log("form data", JSON.stringify(fmData));

    try {
      const response = await axios.post(ADD_BIO_DATA, fmData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      const borrower_id = response?.data?.borrower_id;
      window.localStorage.setItem("borrower_id", borrower_id);
      message.success("You have succesfully saved bio data", 5);
      setCurrent(1);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card className="criclebox ">
      <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
        <Row span={24}>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="First Name"
                    label="First Name"
                    onChange={onChangeFirstName}
                    value={first_name}
                    rules={[
                      { required: true, message: "Please input First name" },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Last Name"
                    label="Last Name"
                    onChange={onChangeLastName}
                    value={last_name}
                    rules={[
                      { required: true, message: "Please input Last name" },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="gender"
                    id="gender"
                    label="Gender"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select Gender"
                      onChange={onChangeGender}
                      allowClear
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Date of birth"
                    id="Date of birth"
                    label="Date of birth"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      placeholder="Select Dob"
                      style={{ width: "100%" }}
                      onChange={onChangeDateOfBirth}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Identity Number"
                    label="National Id/Passport Number"
                    onChange={onChangeIdNo}
                    value={identity_number}
                    rules={[
                      {
                        required: true,
                        message: "Please input National Id/Passport Number",
                      },
                    ]}
                  >
                    <Input placeholder="National ID/ Passport Number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="ID upload"
                    label="Upload"
                  >
                    <Upload
                      onChange={onChangeIdNoLink}
                      beforeUpload={() => false}
                      name="files"
                      accept=".apng,.avif,.gif,.jpg,.jpeg,.jfif,.pjpeg,.pjp,.png,.svg,.webp"
                      action=""
                    >
                      <Button
                        icon={<AiOutlineUpload size={20} />}
                        style={{ borderColor: "#FE471E" }}
                      >
                        Upload ID/Passport Photo
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Tax Number"
                    label="TAX PIN(e.g. KRA/FIRS)"
                    onChange={onChangeTaxNumber}
                    value={tax_number}
                    rules={[{ required: true, message: "Please input PIN" }]}
                  >
                    <Input placeholder="TAX PIN(e.g. KRA/FIRS)" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Postal Address"
                    label="Postal Address"
                    onChange={onChangePostalAddress}
                    value={postal_address}
                    rules={[
                      {
                        required: true,
                        message: "Please input Postal address",
                      },
                    ]}
                  >
                    <Input placeholder="Postal Address" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Physical Address"
                    label="Physical address"
                    onChange={onChangePhysicalAddress}
                    value={physical_address}
                    rules={[
                      {
                        required: true,
                        message: "Please input Physical address",
                      },
                    ]}
                  >
                    <Input placeholder="Physical Address" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="country"
                    label="Country"
                    onChange={onChangeCountry}
                    value={country}
                    rules={[
                      { required: true, message: "Please input Country" },
                    ]}
                  >
                    <Input placeholder="Country" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="nationality"
                    label="Nationality"
                    onChange={onChangeNationality}
                    value={nationality}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Nationality",
                      },
                    ]}
                  >
                    <Input placeholder="Nationality" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name=" City / Region"
                    label=" City / Region"
                    onChange={onChangeCityRegion}
                    value={city_region}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="City/Region" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="primary_email"
                    label="Primary Email"
                    onChange={onChangePrimaryEmail}
                    value={primary_email}
                    rules={[
                      { required: true, message: "Please input your Email" },
                      { type: "email" },
                    ]}
                  >
                    <Input placeholder="Primary Email Address" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="secondary_email"
                    label="Secondary Email"
                    onChange={onChangeSecondaryEmail}
                    value={secondary_email}
                    rules={[{ required: true, type: "email" }]}
                  >
                    <Input placeholder="Secondary Email Address" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="primary_phone_no"
                    label="Primary Phone Number"
                    onChange={onChangePrimaryPhoneNo}
                    value={primary_phone_no}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Primary Phone Number" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="secondary_phone_no"
                    label="Secondary Phone Number"
                    onChange={onChangeSecondaryPhoneNo}
                    value={secondary_phone_no}
                    rules={[
                      { required: true, message: "Secondary Phone Number" },
                    ]}
                  >
                    <Input placeholder="Secondary Phone Number" />
                  </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: "#FE471E",
                      width: "90%",
                      borderColor: "#FE471E",
                    }}
                  >
                    <p style={{ color: "#ffff" }}>SAVE AND CONTINUE</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BioData;
