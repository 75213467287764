import {
  Form,
  Row,
  Col,
  Input,
  Typography,
  Divider,
  Avatar,
  Card,
  Button,
  message,
} from "antd";
import { useState } from "react";
import axios from "../../api/axios";
import { RiNumber2, RiNumber1 } from "react-icons/ri";
const ADD_BIO_DATA = "/v1/borrower/";
const ADD_PERSONAL_REFERENCE = "/guarantor/";

const { Title } = Typography;

const GuarantorReferenceForm = ({ setCurrent, setSuccess }) => {
  const borrower_id = window.localStorage.getItem("borrower_id");
  const [first_name1, setFirstName1] = useState("");
  const [last_name1, setLastName1] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone_no1, setPhoneNo1] = useState("");
  const [identity_number1, setIdentityNumber1] = useState("");
  const [first_name2, setFirstName2] = useState("");
  const [last_name2, setLastName2] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone_no2, setPhoneNo2] = useState("");
  const [identity_number2, setIdentityNumber2] = useState("");

  const onChangeFirstName1 = (e) => {
    const first_name1 = e.target.value;
    setFirstName1(first_name1);
  };
  const onChangeLastName1 = (e) => {
    const last_name1 = e.target.value;
    setLastName1(last_name1);
  };
  const onChangeEmail = (e) => {
    const email1 = e.target.value;
    setEmail1(email1);
  };
  const onChangePhoneNo1 = (e) => {
    const phone_no1 = e.target.value;
    setPhoneNo1(phone_no1);
  };
  const onChangeIdentityNumber1 = (e) => {
    const identity_number1 = e.target.value;
    setIdentityNumber1(identity_number1);
  };
  const onChangeFirstName2 = (e) => {
    const first_name2 = e.target.value;
    setFirstName2(first_name2);
  };
  const onChangeLastName2 = (e) => {
    const last_name2 = e.target.value;
    setLastName2(last_name2);
  };
  const onChangeEmail2 = (e) => {
    const email2 = e.target.value;
    setEmail2(email2);
  };
  const onChangePhoneNo2 = (e) => {
    const phone_no2 = e.target.value;
    setPhoneNo2(phone_no2);
  };
  const onChangeIdentityNumber2 = (e) => {
    const identity_number2 = e.target.value;
    setIdentityNumber2(identity_number2);
  };

  const handleSubmit = async () => {
    console.log(
      "personal reference::",
      first_name1,
      last_name1,
      email1,
      phone_no1,
      identity_number1,
      first_name2,
      last_name2,
      email2,
      phone_no2,
      identity_number2
    );

    await axios
      .all([
        axios.post(
          `${ADD_BIO_DATA}${borrower_id}${ADD_PERSONAL_REFERENCE}`,
          JSON.stringify({
            first_name: first_name1,
            last_name: last_name1,
            email: email1,
            phone_no: phone_no1,
            identity_number: identity_number1,
          }),
          {
            headers: {
              "content-type": "application/json",
            },
            withCredentials: true,
          }
        ),
        axios.post(
          `${ADD_BIO_DATA}${borrower_id}${ADD_PERSONAL_REFERENCE}`,
          JSON.stringify({
            first_name: first_name2,
            last_name: last_name2,
            email: email2,
            phone_no: phone_no2,
            identity_number: identity_number2,
          }),
          {
            headers: {
              "content-type": "application/json",
            },
            withCredentials: true,
          }
        ),
        message.success("You have succesfully saved Guarantor References", 5),
        message.success("You have succesfully Completed Onboarding", 5),
        setSuccess(true),
        setCurrent(0),
      ])
      .then(
        axios.spread((data1, data2) => {
          // output of req.
          console.log("data1", data1, "data2", data2);
        })
      );
  };
  return (
    <Card className="criclebox ">
      <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
        <Row span={24}>
          <Col span={22}>
            <Title level={5}>Guarantor References</Title>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={11}>
                  <Col span={24}>
                    <Avatar.Group>
                      <Avatar
                        style={{ backgroundColor: "#FE471E" }}
                        shape="circle"
                        icon={<RiNumber1 style={{ color: "#ffff" }} />}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">
                          Guarantor Reference 2
                        </h4>
                      </div>
                    </Avatar.Group>
                  </Col>
                  <br></br>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g1_first_name"
                          label="First Name"
                          onChange={onChangeFirstName1}
                          value={first_name1}
                          rules={[
                            {
                              required: true,
                              message: "Please input First name",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g1_last_name"
                          label="Last Name"
                          onChange={onChangeLastName1}
                          value={last_name1}
                          rules={[
                            {
                              required: true,
                              message: "Please input Last name",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g1_id"
                          label="ID Number"
                          onChange={onChangeIdentityNumber1}
                          value={identity_number1}
                          rules={[
                            {
                              required: true,
                              message: "Please input ID Number",
                            },
                          ]}
                        >
                          <Input placeholder="ID" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g1_phone_number"
                          label="Phone Number"
                          onChange={onChangePhoneNo1}
                          value={phone_no1}
                          rules={[
                            {
                              required: true,
                              message: "Please input Phone Nummber",
                            },
                          ]}
                        >
                          <Input placeholder="Phone Nummber" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g1_email"
                          label="Email"
                          onChange={onChangeEmail}
                          value={email1}
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please input Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Divider type="vertical" />
                <Col span={11}>
                  <Col span={24}>
                    <Avatar.Group>
                      <Avatar
                        style={{ backgroundColor: "#FE471E" }}
                        shape="circle"
                        icon={<RiNumber2 style={{ color: "#ffff" }} />}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">
                          Guarantor Reference 2
                        </h4>
                      </div>
                    </Avatar.Group>
                  </Col>
                  <br></br>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g2_first_name"
                          label="First Name"
                          onChange={onChangeFirstName2}
                          value={first_name2}
                          rules={[
                            {
                              required: true,
                              message: "Please input First name",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g2_last_name"
                          label="Last Name"
                          onChange={onChangeLastName2}
                          value={last_name2}
                          rules={[
                            {
                              required: true,
                              message: "Please input Last name",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g2_id"
                          label="ID Number"
                          onChange={onChangeIdentityNumber2}
                          value={identity_number2}
                          rules={[
                            {
                              required: true,
                              message: "Please input ID Number",
                            },
                          ]}
                        >
                          <Input placeholder="ID" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g2_phone_number"
                          label="Phone Number"
                          onChange={onChangePhoneNo2}
                          value={phone_no2}
                          rules={[
                            {
                              required: true,
                              message: "Please input Phone Nummber",
                            },
                          ]}
                        >
                          <Input placeholder="Phone Nummber" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="g2_email"
                          label="Email"
                          onChange={onChangeEmail2}
                          value={email2}
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please input Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={8} offset={9}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: "#FE471E",
                      width: "90%",
                      borderColor: "#FE471E",
                    }}
                  >
                    <p style={{ color: "#ffff" }}>COMPLETE ONBOARDING</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GuarantorReferenceForm;
