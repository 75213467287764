import { Col } from "antd";
import React from "react";

const TransactionHeaders = () => {
  return (
    <>
      <Col span={9} className="transaction_header">
        <p>Factor</p>
      </Col>
      <Col span={2} className="transaction_header">
        <p>Weighting </p>
      </Col>
      <Col span={3} className="transaction_header">
        <p>Actual Value</p>
      </Col>
      <Col span={7} className="transaction_header">
        <p>Factor Quality</p>
      </Col>
      <Col span={3} className="transaction_header">
        <p>Auto-Scoring</p>
      </Col>
    </>
  );
};

export default TransactionHeaders;
