import { Card, Modal, Table } from "antd";
import React from "react";

const Approvers = ({ view_approver, setViewApprover, approvalsData }) => {
  const columns = [
    {
      title: "State",
      dataIndex: "state",
      key: "state ",
    },
    {
      title: "Approver Name",
      dataIndex: "approver_name",
      key: "approver_name ",
    },
    {
      title: "Date Aprroved",
      dataIndex: "date_approved",
      key: "date_approved ",
    },
  ];
  return (
    <Card className="criclebox ">
      <Modal
        title="Approver List"
        okText="Exit"
        visible={view_approver}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setViewApprover(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Table
          columns={columns}
          dataSource={approvalsData}
          pagination={false}
        />
      </Modal>
    </Card>
  );
};

export default Approvers;
