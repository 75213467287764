import {Layout, Row, Col, Typography, Form, Input, Select, Button, Card, message} from 'antd';
import React, {useState} from 'react';
import Sidebar from '../components/layout/Sidebar';
import axios from '../api/axios';
import {Navigate} from 'react-router-dom';

const ADD_BORROWER = '/v1/borrower/';
const ADD_FACILITY = '/facility/';
const {Content} = Layout;
const {Title} = Typography;
const {Option} = Select;

const FacilityRisk = () => {
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');

  const [restricted_cash, setRestrictedCash] = useState(0);
  const [borrower_debt, setBorrowerDebt] = useState(0);
  const [hectares_booked, setHectaresBooked] = useState(0);
  const [rev_last_12_months, setRevLast12Months] = useState(0);
  const [annual_op_expenses, setAnnualOpExpense] = useState(0);
  const [rev_trend_last_12_months, setRevTrendLast12Months] = useState(0);
  const [rev_trand_prior_12_months, setRevTrandPrior12Months] = useState(0);
  const [borrower_income, setBorrowerIncome] = useState(0);
  const [history_default, setHistoryDefault] = useState(0);
  const [success, setSuccess] = useState(false);

  const onChangeRestrictedCash = (e) => {
    const x = e.target.value;
    setRestrictedCash(x);
  };
  const onChangeBorrowerDebt = (e) => {
    const x = e.target.value;
    setBorrowerDebt(x);
  };
  const onChangeHectaresBooked = (e) => {
    const x = e.target.value;
    setHectaresBooked(x);
  };
  const onChangeRevLast12Months = (e) => {
    const x = e.target.value;
    setRevLast12Months(x);
  };
  const onChangeAnnualOpExpense = (e) => {
    const x = e.target.value;
    setAnnualOpExpense(x);
  };
  const onChangeRevTrendLast12Months = (e) => {
    const x = e.target.value;
    setRevTrendLast12Months(x);
  };
  const onChangeRevTrandPrior12Months = (e) => {
    const x = e.target.value;
    setRevTrandPrior12Months(x);
  };
  const onChangeBorrowerIncome = (e) => {
    const x = e.target.value;
    setBorrowerIncome(x);
  };
  const onChangeHistoryDefault = (value) => {
    const x = value;
    setHistoryDefault(x);
  };

  const formData = {
    restricted_cash,
    borrower_debt,
    borrower_income,
    hectares_booked,
    rev_last_12_months,
    annual_op_expenses,
    rev_trend_last_12_months,
    rev_trand_prior_12_months,
    history_default,
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${ADD_BORROWER}${borrower_id}${ADD_FACILITY}`,
        JSON.stringify(formData),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const facility_risk_input_id = response?.data?.inputs?.facility_risk_input_id;
      window.localStorage.setItem('facility_risk_input_id', facility_risk_input_id);
      message.success('You have succesfully completed Facility Risk scoring', 5);
      setSuccess(true);
    } catch (err) {
      message.warn('Something went wrong. Please check your data', 5);
    }
  };
  if (success) {
    return <Navigate to='/single-facility-risk' />;
  }
  if (!token) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={22} offset={1}>
              <Title level={3}>Facility Risk Rating</Title>
            </Col>
            <Col span={22} offset={1}>
              <Title level={5}>Facility Risk Rating</Title>
            </Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Form layout='vertical' className='row-col' onFinish={handleSubmit}>
                  <Row span={24}>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Restricted Cash'
                              label='Restricted Cash'
                              onChange={onChangeRestrictedCash}
                              value={restricted_cash}
                              rules={[{required: true, message: 'Please input Restricted Cash'}]}
                            >
                              <Input placeholder='Restricted Cash' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Borrower Debt'
                              label='Borrower Debt'
                              onChange={onChangeBorrowerDebt}
                              value={borrower_debt}
                              rules={[{required: true, message: 'Please input Borrower Debt'}]}
                            >
                              <Input placeholder='Borrower Debt' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Borrower Income'
                              label='Borrower Income'
                              onChange={onChangeBorrowerIncome}
                              value={borrower_income}
                              rules={[{required: true, message: 'Please input Borrower Income'}]}
                            >
                              <Input placeholder='Borrower Income' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Hectares Booked'
                              label='Hectares Booked'
                              onChange={onChangeHectaresBooked}
                              value={hectares_booked}
                              rules={[{required: true, message: 'Please input Hectares Booked'}]}
                            >
                              <Input placeholder='Hectares Booked' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='revenue'
                              label='Revenue (Last 12 months)'
                              onChange={onChangeRevLast12Months}
                              value={rev_last_12_months}
                              rules={[
                                {required: true, message: 'Please input Revenue (Last 12 months)'},
                              ]}
                            >
                              <Input placeholder='Revenue (Last 12 months)' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Annual Operating Expenses'
                              label='Annual Operating Expenses'
                              onChange={onChangeAnnualOpExpense}
                              value={annual_op_expenses}
                              rules={[
                                {required: true, message: 'Please input Annual Operating Expenses'},
                              ]}
                            >
                              <Input placeholder='Annual Operating Expenses' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='Revenue Trend Last 12 months'
                              label='Revenue Trend Last 12 months'
                              onChange={onChangeRevTrendLast12Months}
                              value={rev_trend_last_12_months}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Revenue Trend Last 12 months',
                                },
                              ]}
                            >
                              <Input placeholder='Revenue Trend Last 12 months' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name="Revenue Trend Prior 12 months'"
                              label='Revenue Trend Prior 12 months'
                              onChange={onChangeRevTrandPrior12Months}
                              value={rev_trand_prior_12_months}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Revenue Trend Prior 12 months',
                                },
                              ]}
                            >
                              <Input placeholder='Revenue Trend Prior 12 months' />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              className='username'
                              name='History of Default'
                              id='History of Default'
                              label='History of Default'
                              rules={[{required: true}]}
                            >
                              <Select
                                placeholder='History of Default'
                                onChange={onChangeHistoryDefault}
                              >
                                <Option value='Delinquency on record - but no default called'>
                                  Delinquency on record - but no default called
                                </Option>
                                <Option value='No history of delinquency or default'>
                                  No history of delinquency or default
                                </Option>
                                <Option value='Default on record'>Default on record</Option>
                                <Option value='Loan is not performing'>
                                  Loan is not performing
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={8} offset={10}>
                            <Button
                              type='primary'
                              htmlType='submit'
                              style={{background: '#FE471E', width: '50%', borderColor: '#FE471E'}}
                            >
                              <p style={{color: '#ffff'}}>SUBMIT</p>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default FacilityRisk;
