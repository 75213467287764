import {
  Form,
  Row,
  Col,
  Input,
  Typography,
  Divider,
  Avatar,
  Card,
  Button,
  message,
} from "antd";
import { useState } from "react";
import { RiNumber2, RiNumber1 } from "react-icons/ri";
import axios from "../../api/axios";

const { Title } = Typography;
const ADD_BIO_DATA = "/v1/borrower/";
const ADD_PERSONAL_REFERENCE = "/reference/";

const BusinessReference = ({ setCurrent }) => {
  const borrower_id = window.localStorage.getItem("borrower_id");
  const [first_name1, setFirstName1] = useState("");
  const [last_name1, setLastName1] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone_no1, setPhoneNo1] = useState("");
  const [borrower_relation1, setBorroweRelation1] = useState("");
  const [first_name2, setFirstName2] = useState("");
  const [last_name2, setLastName2] = useState("");
  const [email2, setEmail2] = useState("");
  const [phone_no2, setPhoneNo2] = useState("");
  const [borrower_relation2, setBorroweRelation2] = useState("");
  const [ref_type] = useState("business");

  const onChangeFirstName1 = (e) => {
    const first_name1 = e.target.value;
    setFirstName1(first_name1);
  };
  const onChangeLastName1 = (e) => {
    const last_name1 = e.target.value;
    setLastName1(last_name1);
  };
  const onChangeEmail = (e) => {
    const email1 = e.target.value;
    setEmail1(email1);
  };
  const onChangePhoneNo1 = (e) => {
    const phone_no1 = e.target.value;
    setPhoneNo1(phone_no1);
  };
  const onChangeBRelation1 = (e) => {
    const borrower_relation1 = e.target.value;
    setBorroweRelation1(borrower_relation1);
  };
  const onChangeFirstName2 = (e) => {
    const first_name2 = e.target.value;
    setFirstName2(first_name2);
  };
  const onChangeLastName2 = (e) => {
    const last_name2 = e.target.value;
    setLastName2(last_name2);
  };
  const onChangeEmail2 = (e) => {
    const email2 = e.target.value;
    setEmail2(email2);
  };
  const onChangePhoneNo2 = (e) => {
    const phone_no2 = e.target.value;
    setPhoneNo2(phone_no2);
  };
  const onChangeBRelation2 = (e) => {
    const borrower_relation2 = e.target.value;
    setBorroweRelation2(borrower_relation2);
  };

  const handleSubmit = async () => {
    console.log(
      "personal reference::",
      ref_type,
      first_name1,
      last_name1,
      email1,
      phone_no1,
      borrower_relation1,
      first_name2,
      last_name2,
      email2,
      phone_no2,
      borrower_relation2
    );

    await axios
      .all([
        axios.post(
          `${ADD_BIO_DATA}${borrower_id}${ADD_PERSONAL_REFERENCE}`,
          JSON.stringify({
            ref_type: ref_type,
            first_name: first_name1,
            last_name: last_name1,
            email: email1,
            phone_no: phone_no1,
            borrower_relation: borrower_relation1,
          }),
          {
            headers: {
              "content-type": "application/json",
            },
            withCredentials: true,
          }
        ),
        axios.post(
          `${ADD_BIO_DATA}${borrower_id}${ADD_PERSONAL_REFERENCE}`,
          JSON.stringify({
            ref_type: ref_type,
            first_name: first_name2,
            last_name: last_name2,
            email: email2,
            phone_no: phone_no2,
            borrower_relation: borrower_relation2,
          }),
          {
            headers: {
              "content-type": "application/json",
            },
            withCredentials: true,
          }
        ),
        message.success("You have succesfully saved Business References", 5),
        setCurrent(3),
      ])
      .then(
        axios.spread((data1, data2) => {
          // output of req.
          console.log("data1", data1, "data2", data2);
        })
      );
  };
  return (
    <Card className="criclebox ">
      <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
        <Row span={24}>
          <Col span={22}>
            <Title level={5}>Business References</Title>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={11}>
                  <Col span={24}>
                    <Avatar.Group>
                      <Avatar
                        style={{ backgroundColor: "#FE471E" }}
                        shape="circle"
                        icon={<RiNumber1 style={{ color: "#ffff" }} />}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">
                          Business Reference 1
                        </h4>
                      </div>
                    </Avatar.Group>
                  </Col>
                  <br></br>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b1_first_name"
                          label="First Name"
                          onChange={onChangeFirstName1}
                          value={first_name1}
                          rules={[
                            {
                              required: true,
                              message: "Please input First name",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b1_last_name"
                          label="Last Name"
                          onChange={onChangeLastName1}
                          value={last_name1}
                          rules={[
                            {
                              required: true,
                              message: "Please input Last name",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b1_relation"
                          label="Relation to the Borrower"
                          onChange={onChangeBRelation1}
                          value={borrower_relation1}
                          rules={[
                            {
                              required: true,
                              message: "Please input Relation to the Borrower",
                            },
                          ]}
                        >
                          <Input placeholder="Relation to the Borrower" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b1_phone_number"
                          label="Phone Number"
                          onChange={onChangePhoneNo1}
                          value={phone_no1}
                          rules={[
                            {
                              required: true,
                              message: "Please input Phone Nummber",
                            },
                          ]}
                        >
                          <Input placeholder="Phone Nummber" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b1_email"
                          label="Email"
                          onChange={onChangeEmail}
                          value={email1}
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please input Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Divider type="vertical" />
                <Col span={11}>
                  <Col span={24}>
                    <Avatar.Group>
                      <Avatar
                        style={{ backgroundColor: "#FE471E" }}
                        shape="circle"
                        icon={<RiNumber2 style={{ color: "#ffff" }} />}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">
                          Business Reference 2
                        </h4>
                      </div>
                    </Avatar.Group>
                  </Col>
                  <br></br>
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b2_first_name"
                          label="First Name"
                          onChange={onChangeFirstName2}
                          value={first_name1}
                          rules={[
                            {
                              required: true,
                              message: "Please input First name",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b2_last_name"
                          label="Last Name"
                          onChange={onChangeLastName2}
                          value={last_name2}
                          rules={[
                            {
                              required: true,
                              message: "Please input Last name",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b2_relation"
                          label="Relation to the Borrower"
                          onChange={onChangeBRelation2}
                          value={borrower_relation2}
                          rules={[
                            {
                              required: true,
                              message: "Please input Relation to the Borrower",
                            },
                          ]}
                        >
                          <Input placeholder="Relation to the Borrower" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b2_phone_number"
                          label="Phone Number"
                          onChange={onChangePhoneNo2}
                          value={phone_no2}
                          rules={[
                            {
                              required: true,
                              message: "Please input Phone Nummber",
                            },
                          ]}
                        >
                          <Input placeholder="Phone Nummber" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="username"
                          name="b2_email"
                          label="Email"
                          onChange={onChangeEmail2}
                          value={email2}
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Please input Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={8} offset={9}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: "#FE471E",
                      width: "90%",
                      borderColor: "#FE471E",
                    }}
                  >
                    <p style={{ color: "#ffff" }}>SAVE AND CONTINUE</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BusinessReference;
