import {Layout, Row, Col, Typography, Button, Card, Space, Descriptions} from 'antd';
import {useCallback, useContext, useEffect, useState} from 'react';
import Sidebar from '../../components/layout/Sidebar';
import axios from '../../api/axios';
import AuthContext from '../../context/AuthContext';
import {Link, Navigate} from 'react-router-dom';
import JudmemtalOverride from '../../components/Forms/JudmemtalOverride';

const BORROWER = '/v1/borrower/';
const FACILITY = '/facility/';
const {Content} = Layout;
const {Title} = Typography;

const SingleFacilityRisk = () => {
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');
  const facility_risk_input_id = window.localStorage.getItem('facility_risk_input_id');
  const [borrower, setBorrower] = useState(null);
  const {setFacility} = useContext(AuthContext);
  const [inputs, setInputs] = useState([]);
  const [scores, setScores] = useState([]);

  const fetchSigleBorrower = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrower(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrower]);

  const fetchFacility = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${FACILITY}${facility_risk_input_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          withCredentials: true,
        }
      );
      setFacility(response?.data);
      setInputs(response?.data?.inputs);
      setScores(response?.data?.scores);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, setFacility, token, facility_risk_input_id]);
  useEffect(() => {
    fetchFacility().catch(console.error);
    fetchSigleBorrower().catch(console.error);
  }, [fetchFacility, fetchSigleBorrower]);
  if (!token) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={22} offset={1}>
              <Title level={3}>Facility Risk </Title>
            </Col>
            <Col span={22} offset={1}>
              <Title level={5}>
                Facility Risk Rating Score ({borrower?.first_name} {borrower?.last_name})
              </Title>
            </Col>
            <Col span={22} offset={1}>
              <Card className='criclebox '>
                <Row span={24}>
                  <Space direction='vertical' size='large'>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={24}>
                            <Descriptions column={{xxl: 4, xl: 3, lg: 3, md: 3, sm: 3, xs: 1}}>
                              <Descriptions.Item label='Restricted Cash'>
                                {inputs?.restricted_cash}
                              </Descriptions.Item>
                              <Descriptions.Item label='Borrower Debt'>
                                {inputs?.borrower_debt}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Borrower Income'>
                                {inputs?.borrower_income}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Hectares Booked'>
                                {inputs?.hectares_booked}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Revenue (Last 12 months)'>
                                {inputs?.rev_last_12_months}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Annual Operating Expenses'>
                                {inputs?.annual_op_expenses}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Revenue Trend Last 12 months'>
                                {inputs?.rev_trend_last_12_months}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='Revenue Trend Prior 12 months'>
                                {inputs?.rev_trand_prior_12_months}{' '}
                              </Descriptions.Item>
                              <Descriptions.Item label='History of Default'>
                                {inputs?.history_default}{' '}
                              </Descriptions.Item>
                            </Descriptions>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={10} offset={7}>
                            <Title level={4}>
                              Facility Risk scores for {borrower?.first_name} {borrower?.last_name}
                            </Title>
                          </Col>
                          <Col span={5}></Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Liquidity <p className='amount_p'>{scores?.liquidity}</p>
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Market Share<p className='amount_p'>{scores?.market_share}</p>{' '}
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Profiltability <p className='amount_p'>{scores?.profitability}</p>
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Revenue Trend <p className='amount_p'>{scores?.revenue_trend}</p>
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              History Default <p className='amount_p'>{scores?.history_default}</p>
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Consumer Leverage Ratio{' '}
                              <p className='amount_p'>{scores?.consumer_leverage_ratio}</p>
                            </Title>
                          </Col>
                          <Col span={8}>
                            <Title level={5} style={{color: '#FE471E'}}>
                              Facility Score <p className='amount_p'>{scores?.facility_score}</p>
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={22}>
                      <JudmemtalOverride scores={scores} fetchFacility={fetchFacility} />
                    </Col>
                    <Col span={22}>
                      <div className='number'>
                        <Row align='middle' gutter={[24, 0]}>
                          <Col span={8} offset={10}>
                            <Link to='/dashboard'>
                              <Button
                                style={{
                                  background: '#FE471E',
                                  width: '90%',
                                  borderColor: '#FE471E',
                                }}
                              >
                                <p style={{color: '#ffff'}}>CONTINUE</p>
                              </Button>
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Space>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SingleFacilityRisk;
