import { Button, Layout, Menu, message, Modal, Popover } from "antd";
import { useContext, useState } from "react";
import {
  AiFillDashboard,
  AiOutlineLogout,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Navigate, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import AuthContext from "../../context/AuthContext";

const { Sider } = Layout;

const Sidebar = () => {
  const { setAuth, setTransaction } = useContext(AuthContext);
  const [loggedOut, setloggedOut] = useState(false);
  const content = <p>Content</p>;
  const logout = () =>
    Modal.confirm({
      title: "Are you sure you want to log out?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        window.localStorage.clear();
        setAuth([]);
        setTransaction([]);
        message.success("You have succesfully Logged Out", 5);
        setloggedOut(true);
      },
    });
  if (loggedOut) {
    return <Navigate to="/" />;
  }
  return (
    <Sider
      width={70}
      style={{
        height: "100vh",
        backgroundColor: "#ffff",
        borderBlockColor: "#e5e5e5",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <img
        src={logo}
        alt=""
        style={{ width: "70%", marginLeft: "15%", marginBottom: "30%" }}
      />
      <Menu>
        <Menu.Item key="1">
          <Popover content="Dashboard" placement="right" trigger="hover">
            <NavLink to="/general-dashboard">
              <span className="icon">
                <AiFillDashboard size={30} />
              </span>
            </NavLink>
          </Popover>
        </Menu.Item>

        <Menu.Item key="2">
          <Popover content="Borrowers" placement="right" trigger="hover">
            <NavLink to="/dashboard">
              <span className="icon">
                <AiOutlineUnorderedList size={30} />
              </span>
            </NavLink>
          </Popover>
        </Menu.Item>
        <Menu.Item key="3" onClick={logout}>
          <Popover content="Logout" placement="right" trigger="hover">
            <span className="icon">
              <AiOutlineLogout size={30} />
            </span>
          </Popover>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
