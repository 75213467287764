import { Card, Modal, Form, Col, Input, Row, message } from "antd";
import axios from "../../api/axios";

const BORROWER = "/v1/borrower/";
const REFERENCE = "/reference/";

const EditReference = ({
  isEditingReference,
  resetEditing,
  editReference,
  setEditReference,
  setBorrowersRef,
  fetchReferenceData,
}) => {
  const token = window.localStorage.getItem("Bearer");
  const patchReferenceData = async (data) => {
    try {
      await axios.patch(
        `${BORROWER}${data?.borrower_id}${REFERENCE}${data?.reference_id}`,
        JSON.stringify({
          first_name: data?.first_name,
          last_name: data?.last_name,
          email: data?.email,
          phone_no: data?.phone_no,
          borrower_relation: data?.borrower_relation,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      message.success("You have successfully changed reference data", 5);
      fetchReferenceData();
    } catch (error) {
      console.log(error);
      message.warn("Somethingwent wrong.Try Again", 5);
    }
  };
  return (
    <Card className="criclebox ">
      <Modal
        title="Edit Reference Information"
        okText="Save"
        visible={isEditingReference}
        onCancel={() => {
          resetEditing();
        }}
        onOk={() => {
          setBorrowersRef((pre) => {
            return pre.map((record) => {
              if (record?.reference_id === editReference?.reference_id) {
                return patchReferenceData(editReference);
              } else {
                return record;
              }
            });
          });
          resetEditing();
        }}
      >
        <div className="number">
          <Row align="middle" gutter={[24, 0]}>
            <Col span={11}>
              <Form.Item className="username" label="First Name">
                <Input
                  value={editReference?.first_name}
                  onChange={(e) => {
                    setEditReference((pre) => {
                      return { ...pre, first_name: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item className="username" label="Last Name">
                <Input
                  value={editReference?.last_name}
                  onChange={(e) => {
                    setEditReference((pre) => {
                      return { ...pre, last_name: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item className="username" label="Email">
                <Input
                  value={editReference?.email}
                  onChange={(e) => {
                    setEditReference((pre) => {
                      return { ...pre, email: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item className="username" label="Phone Number">
                <Input
                  value={editReference?.phone_no}
                  onChange={(e) => {
                    setEditReference((pre) => {
                      return { ...pre, phone_no: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item className="username" label="Borrower Relation">
                <Input
                  value={editReference?.borrower_relation}
                  onChange={(e) => {
                    setEditReference((pre) => {
                      return { ...pre, borrower_relation: e.target.value };
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>
    </Card>
  );
};

export default EditReference;
