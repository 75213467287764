import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [transaction, setTransaction] = useState({});
  const [facility, setFacility] = useState({});
  const [credit, setCredit] = useState({});
  const [borrower, setBorrower] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        transaction,
        setTransaction,
        facility,
        setFacility,
        credit,
        setCredit,
        borrower,
        setBorrower,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
