import React, {useContext, useRef, useState} from 'react';
import qs from 'qs';
import {Row, Col, Typography, Layout, Form, Input, Button, Checkbox, message} from 'antd';
import {Link, Navigate} from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import headerlogo from '../assets/images/ui-log.png';
import logo from '../assets/images/logo.png';
import axios from '../api/axios';
const LOGIN_URL = '/auth';
const {Title} = Typography;
const {Content} = Layout;
const SignIn = () => {
  const {setAuth} = useContext(AuthContext);
  const userRef = useRef(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify({username, password}), {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      const access_token = response?.data?.access_token;
      setAuth({username, password, access_token});
      window.localStorage.setItem('Bearer', access_token);
      setPassword('');
      setUsername('');
      setSuccess(true);
      message.success('You have successfully logged in', 5);
    } catch (err) {
      if (!err?.response) {
        message.warn('No Server Response', 5);
      } else if (err.response?.status === 400) {
        message.warn('Missing Username or Password', 5);
      } else if (err.response?.status === 401) {
        message.warn('Incorrect username or password', 5);
      } else {
        message.warn('Login Failed', 5);
      }
    }
  };

  if (success) {
    return <Navigate to='/dashboard' />;
  }
  return (
    <>
      <Layout className='layout-default layout-signin' style={{background: '#ffff'}}>
        <Content className='signin'>
          <Row gutter={[24, 0]} justify='space-around'>
            <Col
              className='sign-img'
              style={{alignContent: 'center'}}
              xs={{span: 24}}
              lg={{span: 12}}
              md={{span: 12}}
            >
              <img src={headerlogo} alt='' />
            </Col>
            <Col
              style={{alignContent: 'center'}}
              xs={{span: 24, offset: 0}}
              lg={{span: 11, offset: 1}}
              md={{span: 12}}
            >
              <img src={logo} alt='' style={{width: '25%', marginLeft: '35%'}} />
              <Title level={5} style={{marginLeft: '35%'}}>
                Login to get started
              </Title>
              <Form layout='vertical' className='row-col' onFinish={handleSubmit}>
                <Form.Item
                  className='username'
                  name='username'
                  id='username'
                  ref={userRef}
                  onChange={onChangeUsername}
                  value={username}
                  rules={[{required: true, message: 'Please input your email!'}, {type: 'string'}]}
                >
                  <Input placeholder='Email or Username' />
                </Form.Item>

                <Form.Item
                  className='username'
                  name='password'
                  id='password'
                  onChange={onChangePassword}
                  value={password}
                  rules={[{required: true, message: 'Please input your password!'}]}
                >
                  <Input.Password placeholder='Password' />
                </Form.Item>
                <Form.Item name='remember' valuePropName='checked'>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <Form.Item>
                  <div className='header-col '>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{width: '100%', background: '#FE471E', borderColor: '#FE471E'}}
                      shape='round'
                    >
                      LOG IN
                    </Button>
                  </div>
                </Form.Item>
                <p className='font-semibold text-muted'>
                  If you are interested in tractor financing click{' '}
                  <Link to='/onboarding' className='prominent-text'>
                    here
                  </Link>
                </p>
                <p className='font-semibold text-muted'>
                  Don't have an account?{' '}
                  {/* <Link to="/sign-up" className="text-dark font-bold"> */}
                  Please ask a Hello Tractor Administrator to create one for you.
                  {/* </Link> */}
                </p>
                <p className='font-semibold text-muted'>
                  <Link to='/reset-password' className='text-dark'>
                    Forgot password?
                  </Link>
                </p>
                <p className='font-semibold text-muted'>Request Help</p>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SignIn;
