import { Route, Routes } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "antd/dist/antd.less";
import SignIn from "./pages/SignIn";
import Onboarding from "./pages/Onboarding";
import Transactions from "./pages/Transactions";
import FacilityRisk from "./pages/FacilityRisk";
import CreditApproval from "./pages/CreditApproval.js";
import SignUp from "./pages/SignUp";
import SingleTransaction from "./pages/Singles/SingleTransaction";
import SingleFacilityRisk from "./pages/Singles/SingleFacilityRisk";
import EditBorrower from "./pages/Singles/EditBorrower";
import EditTransaction from "./components/Forms/EditTransaction";
import Dashboard from "./pages/Dashboard";
import Records from "./pages/Records";
import GeneralDash from "./pages/GeneralDash";
import SingleCreditApproval from "./pages/Singles/SingleCreditApproval";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/facility-risk" element={<FacilityRisk />} />
        <Route path="/credit-approval" element={<CreditApproval />} />
        <Route path="/single-transaction" element={<SingleTransaction />} />
        <Route path="/single-facility-risk" element={<SingleFacilityRisk />} />
        <Route
          path="/single-credit-approval"
          element={<SingleCreditApproval />}
        />
        <Route path="/edit-borrower" element={<EditBorrower />} />
        <Route path="/edit-transaction" element={<EditTransaction />} />
        <Route path="/records" element={<Records />} />
        <Route path="/general-dashboard" element={<GeneralDash />} />
      </Routes>
    </>
  );
}

export default App;
