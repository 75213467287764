import {Button, Card, Col, Dropdown, Layout, Menu, Row, Space, Table, Typography} from 'antd';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link, Navigate} from 'react-router-dom';
import axios from '../../api/axios';
import EditBioData from '../../components/Forms/BioData/EditBioData';
import EditGuarantor from '../../components/Forms/EditGuarantor';
import EditReference from '../../components/Forms/EditReference';
import Sidebar from '../../components/layout/Sidebar';
import AuthContext from '../../context/AuthContext';

const {Content} = Layout;
const {Title} = Typography;
const BORROWER = '/v1/borrower/';
const REFERENCE = '/reference/';
const GUARANTOR = '/guarantor/';

const EditBorrower = () => {
  const {borrower, setBorrower} = useContext(AuthContext);
  const token = window.localStorage.getItem('Bearer');
  const borrower_id = window.localStorage.getItem('xd');
  const [borrowers_ref, setBorrowersRef] = useState(null);
  const [borrowers_gnt, setBorrowersGnt] = useState(null);
  const [isEditingReference, setIsEditingReference] = useState(false);
  const [editReference, setEditReference] = useState(null);
  const [isEditingGuarantor, setIsEditingGuarantor] = useState(false);
  const [editGuarantor, setEditGuarantor] = useState(null);
  const [disable, setDisable] = useState(true);

  const ref_columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name ',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name ',
    },
    {
      title: 'Borrower Relation',
      dataIndex: 'borrower_relation',
      key: 'borrower_relation ',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_no',
      key: 'phone_no ',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email ',
    },
    {
      title: 'Reference Type',
      dataIndex: 'ref_type',
      key: 'ref_type ',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => {
              onClickBorrowerRefEdit(record);
            }}
            style={{background: '#FE471E', borderColor: '#FE471E'}}
          >
            <p style={{color: '#ffff'}}>Edit</p>
          </Button>
          <Button
            type='primary'
            onClick={() => {
              onClickBorrowerRefDelete(record);
            }}
            style={{background: '#ffff', borderColor: '#FE471E'}}
          >
            <p style={{color: '#FE471E'}}>Delete</p>
          </Button>
        </Space>
      ),
    },
  ];
  const guarantor_columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name ',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name ',
    },
    {
      title: 'Identity Number',
      dataIndex: 'identity_number',
      key: 'identity_number ',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_no',
      key: 'phone_no ',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email ',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => {
              onClickBorrowerGntEdit(record);
            }}
            style={{background: '#FE471E', borderColor: '#FE471E'}}
          >
            <p style={{color: '#ffff'}}>Edit</p>
          </Button>
          <Button
            type='primary'
            onClick={() => {
              onClickBorrowerGntDelete(record);
            }}
            style={{background: '#ffff', borderColor: '#FE471E'}}
          >
            <p style={{color: '#FE471E'}}>Delete</p>
          </Button>
        </Space>
      ),
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <Link to='/transactions'>
          <p>Transaction Scoring</p>
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link to='/facility-risk'>
          <p>Facility Risk Rating</p>
        </Link>
      </Menu.Item>
      <Menu.Item key={3}>
        <Link to='/credit-approval'>
          <p>Credit Approval</p>
        </Link>
      </Menu.Item>
    </Menu>
  );
  const onClickBorrowerRefEdit = (record) => {
    setIsEditingReference(true);
    setEditReference({...record});
  };
  const onClickBorrowerRefDelete = (record) => {};
  const onClickBorrowerGntEdit = (record) => {
    setIsEditingGuarantor(true);
    setEditGuarantor({...record});
  };
  const onClickBorrowerGntDelete = (record) => {};

  const resetEditing = () => {
    setIsEditingReference(false);
    setEditReference(null);
    setIsEditingGuarantor(false);
    setEditGuarantor(null);
  };

  const fetchSigleBorrower = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrower(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrower]);
  const fetchReferenceData = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${REFERENCE}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrowersRef(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrowersRef]);
  const fetchGuarantorData = useCallback(async () => {
    try {
      const response = await axios.get(`${BORROWER}${borrower_id}${GUARANTOR}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        withCredentials: true,
      });
      setBorrowersGnt(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, token, setBorrowersGnt]);
  useEffect(() => {
    fetchReferenceData().catch(console.error);
    fetchGuarantorData().catch(console.error);
    fetchSigleBorrower().catch(console.error);
  }, [fetchReferenceData, fetchGuarantorData, fetchSigleBorrower]);
  if (!token) {
    return <Navigate to='/' />;
  }
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{backgroundColor: '#ffff'}}>
          <Row span={24}>
            <Col span={22} offset={1}>
              <Title level={3}>Edit Borrower Data</Title>
            </Col>
            <Space direction='vertical' size='large'>
              <Col span={23} offset={1}>
                <div className='number'>
                  <Row align='middle' gutter={[24, 0]}>
                    <Col span={6}>
                      {' '}
                      <Title level={5}>Edit & save Borrower</Title>
                    </Col>
                    <Col span={10}></Col>
                    <Col span={4}>
                      <Link to='/records'>
                        <Button
                          type='primary'
                          htmlType='submit'
                          style={{background: '#FE471E', width: '90%', borderColor: '#FE471E'}}
                        >
                          <p style={{color: '#ffff'}}>View Records</p>
                        </Button>
                      </Link>
                    </Col>
                    <Col span={4}>
                      {' '}
                      <Dropdown overlay={menu} placement='topCenter'>
                        <Button
                          type='primary'
                          htmlType='submit'
                          style={{background: '#ffff', width: '90%', borderColor: '#FE471E'}}
                        >
                          <p style={{color: '#000'}}>Add a Record</p>
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={22} offset={1}>
                {' '}
                <EditBioData
                  setDisable={setDisable}
                  disable={disable}
                  borrower={borrower}
                  setBorrower={setBorrower}
                  fetchSigleBorrower={fetchSigleBorrower}
                />{' '}
              </Col>
              <Col span={22} offset={1}>
                <Card className='criclebox '>
                  <Col span={24}>
                    <Title level={5} style={{color: '#FE471E'}}>
                      References Data{' '}
                    </Title>
                  </Col>
                  <Table columns={ref_columns} dataSource={borrowers_ref} pagination={false} />
                </Card>
              </Col>
              <Col span={22} offset={1}>
                <Card className='criclebox '>
                  <Col span={24}>
                    <Title level={5} style={{color: '#FE471E'}}>
                      Guarantors Data
                    </Title>
                  </Col>
                  <Table
                    columns={guarantor_columns}
                    dataSource={borrowers_gnt}
                    pagination={false}
                  />
                </Card>
              </Col>
            </Space>
          </Row>
          <EditReference
            resetEditing={resetEditing}
            isEditingReference={isEditingReference}
            editReference={editReference}
            setBorrowersRef={setBorrowersRef}
            setEditReference={setEditReference}
            fetchReferenceData={fetchReferenceData}
          />
          <EditGuarantor
            resetEditing={resetEditing}
            isEditingGuarantor={isEditingGuarantor}
            editGuarantor={editGuarantor}
            setBorrowersGnt={setBorrowersGnt}
            setEditGuarantor={setEditGuarantor}
            fetchGuarantorData={fetchGuarantorData}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditBorrower;
