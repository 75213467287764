import {
  Layout,
  Row,
  Col,
  Typography,
  Form,
  Card,
  Divider,
  Input,
  Select,
  Button,
  Space,
  message,
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import Sidebar from "../../components/layout/Sidebar";
import TransactionHeaders from "../../components/TransactionHeaders";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthContext";
import { Navigate } from "react-router-dom";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const BORROWER = "/v1/borrower/";
const TRANSACTION = "/transaction/";

const EditTransaction = () => {
  const token = window.localStorage.getItem("Bearer");
  const borrower_id = window.localStorage.getItem("borrower_id");
  const transaction_value_id = window.localStorage.getItem(
    "transaction_value_id"
  );
  const { transaction, setTransaction } = useContext(AuthContext);
  const [disable] = useState(false);
  const [values, setValues] = useState([]);
  const [qualities, setQualities] = useState([]);
  const [scores, setScores] = useState([]);

  const [cash_on_hand, setCashOnHand] = useState("");
  const [cash_fq, setGCashFq] = useState({});
  const [debt_service_coverage_ratio, setDbtService] = useState("");
  const [debt_service_fq, setDebtServiceFq] = useState({});
  const [strength_of_cashflow_sources_last_12_month, setStrengthOfCashflow] =
    useState("");
  const [cash_flow_fq, setCashFlowFq] = useState({});
  const [ability_to_fund_working_capital, setWorkingCapital] = useState("");
  const [working_capital_fq, setWorkingCapitalFq] = useState({});
  const [business_plan_fq, setBusinessPlanFq] = useState({});
  const [op_history_fq, setOpHistoryFq] = useState({});
  const [average_number_of_hectares, setAvgHec] = useState("");
  const [hectares_fq, setHectaresFq] = useState({});
  const [contractual_security, setContractualSec] = useState("");
  const [contractual_sec_fq, setContractualSecFq] = useState({});
  const [loan_to_value, setLoanValue] = useState("");
  const [loan_to_value_fq, setLoanValueFq] = useState({});
  const [ability_to_capture_excess_payment, setExcessPayment] = useState("");
  const [excess_payment_fq, setExcessPaymentFq] = useState({});
  const [pay_collection_fq, setPayCollectionFq] = useState({});
  const [loan_tenor, setLoanTenor] = useState("");
  const [loan_tenor_fq, setLoanTenorFq] = useState({});
  const [social_guarantees_fq, setSocialGuaranteesFq] = useState({});
  const [cross_guarantees_fq, setCrossGuaranteesFq] = useState({});
  const [serviceable_land_fq, setServiceableLandFq] = useState({});
  const [crops_covered_fq, setCropsCoveredFq] = useState({});
  const [op_competition_fq, setOpCompetitionFq] = useState({});
  const [customer_rel_fq, setCustomerRelFq] = useState({});
  const [technology_fq, setTechnologyFq] = useState({});
  const [required_capital, setRequiredCapital] = useState("");
  const [required_capital_fq, setRequiredCapitalFq] = useState({});
  const [how_many_days_can_tractor_be_idle, setIdleTractors] = useState("");
  const [idle_tractors_fq, setIdleTractorsFq] = useState({});
  const [based_on_route_planning_tool, setRouteTool] = useState("");
  const [route_planning_tool, setRouteToolFq] = useState({});
  const [extension_likelihood_fq, setExtensionLikelihoodFq] = useState({});
  const [transaction_fee, setTransactionFee] = useState("");
  const [transaction_fee_fq, setTransactionFeeFq] = useState({});
  const [transaction_fees_as_a_percentage, setTransactionFeeP] = useState("");
  const [transaction_percentage_fq, setTransactionFeepFq] = useState({});
  const [impact_assessment_fq, setImpactAssessmentFq] = useState({});

  const [cash_score, setCashScore] = useState(0);
  const [debt_score, setDebtScore] = useState(0);
  const [cash_flow_score, setCashFlowScore] = useState(0);
  const [working_capital_score, setWorkingCapitalScore] = useState(0);
  const [business_plan_score, setBusinessPlanScore] = useState(0);
  const [op_history_score, setOpHistoryScore] = useState(0);
  const [hectares_score, setHectaresScore] = useState(0);
  const [contractual_sec_score, setContractualSecScore] = useState(0);
  const [loan_to_value_score, setLoanValueScore] = useState(0);
  const [pay_collection_score, setPayCollectionScore] = useState(0);
  const [excess_payment_score, setExcessPaymentScore] = useState(0);
  const [loan_tenor_score, setLoanTenorScore] = useState(0);
  const [social_guarantees_score, setSocialGuaranteesScore] = useState(0);
  const [cross_guarantees_score, setCrossGuaranteesScore] = useState(0);
  const [serviceable_land_score, setServiceableLandScore] = useState(0);
  const [crops_covered_score, setCropsCoveredScore] = useState(0);
  const [op_competition_score, setOpCompetitionScore] = useState(0);
  const [customer_rel_score, setCustomerRelScore] = useState(0);
  const [technology_score, setTechnologyscore] = useState(0);
  const [required_capital_score, setRequiredCapitalScore] = useState(0);
  const [idle_tractors_score, setIdleTractorsScore] = useState(0);
  const [route_planning_tool_score, setRouteToolScore] = useState(0);
  const [extension_likelihood_score, setExtensionLikelihoodScore] = useState(0);
  const [transaction_fee_score, setTransactionFeeScore] = useState(0);
  const [transaction_percentage_score, setTransactionFeePScore] = useState(0);
  const [Impact_assessment_score, setImpactAssessmentScore] = useState(0);

  const onChangeCashOnHand = (e) => {
    const x = e.target.value;
    setCashOnHand(x);
  };
  const onChangeCash = (value, key) => {
    setGCashFq({ text: key.key, level: value });
    setCashScore(key?.data_value);
  };
  const onChangeDebtService = (e) => {
    const debt_service = e.target.value;
    setDbtService(debt_service);
  };
  const onChangeDebt = (value, key) => {
    setDebtServiceFq({ text: key.key, level: value });
    setDebtScore(key?.data_value);
  };
  const onChangeCashFlow = (e) => {
    const cash_flow = e.target.value;
    setStrengthOfCashflow(cash_flow);
  };
  const onChangeCashFlowFq = (value, key) => {
    setCashFlowFq({ text: key.key, level: value });
    setCashFlowScore(key?.data_value);
  };
  const onChangeWorkingCapital = (e) => {
    const x = e.target.value;
    setWorkingCapital(x);
  };
  const onChangeWorkingCapitalFq = (value, key) => {
    setWorkingCapitalFq({ text: key.key, level: value });
    setWorkingCapitalScore(key?.data_value);
  };
  const onChangeBusinessPlanFq = (value, key) => {
    setBusinessPlanFq({ text: value, level: value });
    setBusinessPlanScore(key?.data_value);
  };
  const onChangeOpHistoryFq = (value, key) => {
    setOpHistoryFq({ text: value, level: value });
    setOpHistoryScore(key?.data_value);
  };
  const onChangeHectares = (e) => {
    const x = e.target.value;
    setAvgHec(x);
  };
  const onChangeHectaresFq = (value, key) => {
    setHectaresFq({ text: key.key, level: value });
    setHectaresScore(key?.data_value);
  };
  const onChangeContractualSec = (e) => {
    const x = e.target.value;
    setContractualSec(x);
  };
  const onChangeContractualSecFq = (value, key) => {
    setContractualSecFq({ text: key.key, level: value });
    setContractualSecScore(key?.data_value);
  };
  const onChangeLoanValue = (e) => {
    const x = e.target.value;
    setLoanValue(x);
  };
  const onChangeLoanValueFq = (value, key) => {
    setLoanValueFq({ text: key.key, level: value });
    setLoanValueScore(key?.data_value);
  };
  const onChangePayCollectionFq = (value, key) => {
    setPayCollectionFq({ text: key.key, level: value });
    setPayCollectionScore(key?.data_value);
  };
  const onChangeExcessPayment = (e) => {
    const x = e.target.value;
    setExcessPayment(x);
  };
  const onChangeExcessPaymentFq = (value, key) => {
    setExcessPaymentFq({ text: key.key, level: value });
    setExcessPaymentScore(key?.data_value);
  };
  const onChangeLoanTenor = (e) => {
    const x = e.target.value;
    setLoanTenor(x);
  };
  const onChangeLoanTenorFq = (value, key) => {
    setLoanTenorFq({ text: key.key, level: value });
    setLoanTenorScore(key?.data_value);
  };
  const onChangeSocialGuaranteesFq = (value, key) => {
    setSocialGuaranteesFq({ text: key.key, level: value });
    setSocialGuaranteesScore(key?.data_value);
  };
  const onChangeCrossGuaranteesFq = (value, key) => {
    setCrossGuaranteesFq({ text: key.key, level: value });
    setCrossGuaranteesScore(key?.data_value);
  };
  const onChangeServiceableLandFq = (value, key) => {
    setServiceableLandFq({ text: value, level: value });
    setServiceableLandScore(key?.data_value);
  };
  const onChangeCropsCoveredFq = (value, key) => {
    setCropsCoveredFq({ text: value, level: value });
    setCropsCoveredScore(key?.data_value);
  };
  const onChangeOpCompetitionFq = (value, key) => {
    setOpCompetitionFq({ text: key.key, level: value });
    setOpCompetitionScore(key?.data_value);
  };
  const onChangeCustomerRelFq = (value, key) => {
    setCustomerRelFq({ text: value, level: value });
    setCustomerRelScore(key?.data_value);
  };
  const onChangeTechnologyFq = (value, key) => {
    setTechnologyFq({ text: value, level: value });
    setTechnologyscore(key?.data_value);
  };
  const onChangeRequiredCapital = (e) => {
    const x = e.target.value;
    setRequiredCapital(x);
  };
  const onChangeRequiredCapitalFq = (value, key) => {
    setRequiredCapitalFq({ text: key.key, level: value });
    setRequiredCapitalScore(key?.data_value);
  };
  const onChangeIdleTractors = (e) => {
    const x = e.target.value;
    setIdleTractors(x);
  };
  const onChangeIdleTractorsFq = (value, key) => {
    setIdleTractorsFq({ text: key.key, level: value });
    setIdleTractorsScore(key?.data_value);
  };
  const onChangeRouteTool = (e) => {
    const x = e.target.value;
    setRouteTool(x);
  };
  const onChangeRouteToolFq = (value, key) => {
    setRouteToolFq({ text: key.key, level: value });
    setRouteToolScore(key?.data_value);
  };
  const onChangeExtensionLikelihoodFq = (value, key) => {
    setExtensionLikelihoodFq({ text: key.key, level: value });
    setExtensionLikelihoodScore(key?.data_value);
  };
  const onChangeTransactionFee = (e) => {
    const x = e.target.value;
    setTransactionFee(x);
  };
  const onChangeTransactionFeeFq = (value, key) => {
    setTransactionFeeFq({ text: key.key, level: value });
    setTransactionFeeScore(key?.data_value);
  };
  const onChangeTransactionFeeP = (e) => {
    const x = e.target.value;
    setTransactionFeeP(x);
  };
  const onChangeTransactionFeePFq = (value, key) => {
    setTransactionFeepFq({ text: key.key, level: value });
    setTransactionFeePScore(key?.data_value);
  };
  const onChangeImpactAssessmentFq = (value, key) => {
    setImpactAssessmentFq({ text: key.key, level: value });
    setImpactAssessmentScore(key?.data_value);
  };

  const getTransaction = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BORROWER}${borrower_id}${TRANSACTION}${transaction_value_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      setDefaults(response);
    } catch (error) {
      console.log(error);
    }
  }, [borrower_id, setTransaction, token, transaction_value_id]);
  useEffect(() => {
    getTransaction().catch(console.error);
  }, [getTransaction]);
  const setDefaults = (response) => {
    setTransaction(response?.data);
    setValues(response?.data?.values);
    setScores(response?.data?.scores);
    setQualities(response?.data?.qualities);
    setCashOnHand(response?.data?.values?.cash_on_hand);
    setGCashFq(response?.data?.qualities?.cash_on_hand);
    setDbtService(response?.data?.values?.debt_service_coverage_ratio);
    setDebtServiceFq(response?.data?.qualities?.debt_service_coverage_ratio);
    setStrengthOfCashflow(
      response?.data?.values?.strength_of_cashflow_sources_last_12_month
    );
    setCashFlowFq(
      response?.data?.qualities?.strength_of_cashflow_sources_last_12_month
    );
    setWorkingCapital(response?.data?.values?.ability_to_fund_working_capital);
    setWorkingCapitalFq(
      response?.data?.qualities?.ability_to_fund_working_capital
    );
    setBusinessPlanFq(
      response?.data?.qualities?.sophistication_of_business_plan
    );
    setOpHistoryFq(response?.data?.qualities?.operating_history);
    setAvgHec(response?.data?.values?.average_number_of_hectares);
    setHectaresFq(response?.data?.qualities?.average_number_of_hectares);
    setContractualSec(response?.data?.values?.contractual_security);
    setContractualSecFq(response?.data?.qualities?.contractual_security);
    setLoanValue(response?.data?.values?.loan_to_value);
    setLoanValueFq(response?.data?.qualities?.loan_to_value);
    setExcessPayment(response?.data?.values?.ability_to_capture_excess_payment);
    setExcessPaymentFq(
      response?.data?.qualities?.ability_to_capture_excess_payment
    );
    setLoanTenor(response?.data?.values?.loan_tenor);
    setLoanTenorFq(response?.data?.qualities?.loan_tenor);
    setPayCollectionFq(
      response?.data?.qualities?.form_of_payment_collection_and_visibility
    );
    setSocialGuaranteesFq(response?.data?.qualities?.social_guarantees);
    setServiceableLandFq(response?.data?.qualities?.size_of_serviceable_land);
    setCrossGuaranteesFq(response?.data?.qualities?.cross_guarantee_and_cross);
    setCropsCoveredFq(
      response?.data?.qualities?.essentiality_of_the_crops_covered
    );
    setOpCompetitionFq(response?.data?.qualities?.competition_in_the_area);
    setCustomerRelFq(
      response?.data?.qualities
        ?.nature_of_contractual_relationship_with_customers
    );
    setTechnologyFq(
      response?.data?.qualities?.technology_commercially_proven_or_untested
    );
    setRequiredCapital(response?.data?.values?.required_capital);
    setRequiredCapitalFq(response?.data?.qualities?.required_capital);
    setIdleTractors(response?.data?.values?.how_many_days_can_tractor_be_idle);
    setIdleTractorsFq(
      response?.data?.qualities?.how_many_days_can_tractor_be_idle
    );
    setRouteTool(response?.data?.values?.based_on_route_planning_tool);
    setRouteToolFq(response?.data?.qualities?.based_on_route_planning_tool);
    setTransactionFee(response?.data?.values?.transaction_fee);
    setTransactionFeeFq(response?.data?.qualities?.transaction_fee);
    setTransactionFeeP(
      response?.data?.values?.transaction_fees_as_a_percentage
    );
    setTransactionFeepFq(
      response?.data?.qualities?.transaction_fees_as_a_percentage
    );
    setImpactAssessmentFq(response?.data?.qualities?.impact_assessment);
    setExtensionLikelihoodFq(
      response?.data?.qualities?.likelihood_of_expansion_to_other_ht_services
    );
  };
  //Prepare form json data
  const formData = {
    values: {
      cash_on_hand,
      debt_service_coverage_ratio,
      strength_of_cashflow_sources_last_12_month,
      ability_to_fund_working_capital,
      average_number_of_hectares,
      contractual_security,
      loan_to_value,
      ability_to_capture_excess_payment,
      loan_tenor,
      required_capital,
      how_many_days_can_tractor_be_idle,
      based_on_route_planning_tool,
      transaction_fee,
      transaction_fees_as_a_percentage,
    },
    quality: {
      cash_on_hand: cash_fq,
      debt_service_coverage_ratio: debt_service_fq,
      strength_of_cashflow_sources_last_12_month: cash_flow_fq,
      ability_to_fund_working_capital: working_capital_fq,
      sophistication_of_business_plan: business_plan_fq,
      operating_history: op_history_fq,
      average_number_of_hectares: hectares_fq,
      contractual_security: contractual_sec_fq,
      loan_to_value: loan_to_value_fq,
      form_of_payment_collection_and_visibility: pay_collection_fq,
      ability_to_capture_excess_payment: excess_payment_fq,
      loan_tenor: loan_tenor_fq,
      social_guarantees: social_guarantees_fq,
      cross_guarantee_and_cross: cross_guarantees_fq,
      size_of_serviceable_land: serviceable_land_fq,
      essentiality_of_the_crops_covered: crops_covered_fq,
      competition_in_the_area: op_competition_fq,
      nature_of_contractual_relationship_with_customers: customer_rel_fq,
      technology_commercially_proven_or_untested: technology_fq,
      required_capital: required_capital_fq,
      how_many_days_can_tractor_be_idle: idle_tractors_fq,
      based_on_route_planning_tool: route_planning_tool,
      likelihood_of_expansion_to_other_ht_services: extension_likelihood_fq,
      transaction_fee: transaction_fee_fq,
      transaction_fees_as_a_percentage: transaction_percentage_fq,
      impact_assessment: impact_assessment_fq,
    },
  };
  const handleSubmit = async () => {
    console.log("transaction data is::", transaction);
    console.log("edited transaction data is::", formData);
    try {
      await axios.patch(
        `${BORROWER}${borrower_id}${TRANSACTION}${transaction_value_id}`,
        JSON.stringify(formData),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          withCredentials: true,
        }
      );
      message.success(
        "You have successfull changed borrower transaction data",
        5
      );
      getTransaction();
    } catch (error) {
      console.log(error);
      message.warn("Somethingwent wrong.Try Again", 5);
    }
  };
  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content style={{ backgroundColor: "#ffff" }}>
          <Row span={24}>
            <Col span={23} offset={1}>
              <Title level={3}>Recorded Transaction</Title>
            </Col>
            <Col span={23} offset={1}>
              <Title level={5}>PAYG Transaction Scoring Saved Data</Title>
            </Col>
            <Col span={23} offset={1}>
              <Form
                layout="vertical"
                className="row-col"
                onFinish={handleSubmit}
              >
                <Row span={24}>
                  <Space direction="vertical" size="large">
                    {/* Borrower Card */}
                    <Col span={24}>
                      <Card className="criclebox ">
                        <Col span={22} className="transaction_header">
                          <p>Borrower</p>
                        </Col>
                        <Divider />
                        <Col span={23}>
                          <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                              <TransactionHeaders />
                              {/* Cash at hand Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Cash on hand</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="cash on hand value"
                                  >
                                    <Input
                                      placeholder={values?.cash_on_hand}
                                      value={values?.cash_on_hand}
                                      disabled={disable}
                                      onChange={onChangeCashOnHand}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Cash Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.cash_on_hand?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeCash}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key=">70% of debt obligation"
                                      >
                                        Strong ({">"}70% of debt obligation)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="40-70% of debt obligation"
                                      >
                                        Moderate (40-70% of debt obligation)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="< 40% of debt obligation"
                                      >
                                        Weak ({"<"}40% of debt obligation)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.cash_on_hand}</p>
                                </Col>
                              </>
                              {/* Debt Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Debt service coverage ratio</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="Debt value"
                                  >
                                    <Input
                                      placeholder={
                                        values?.debt_service_coverage_ratio
                                      }
                                      disabled={disable}
                                      onChange={onChangeDebtService}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Debt Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.debt_service_coverage_ratio
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeDebt}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key="> 1.25x"
                                      >
                                        Strong ({">"}1.25x)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="1.0-1.25x"
                                      >
                                        Moderate (1.0-1.25x)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="< 1.0x"
                                      >
                                        Weak ({"<"}1.0x)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.debt_service_coverage_ratio}</p>
                                </Col>
                              </>
                              {/* Cashflow strength Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Strength of cashflow sources evidenced by
                                    last [12] months of bank/digital wallet
                                    stmts
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="Strength of cashflow value"
                                  >
                                    <Input
                                      placeholder={
                                        values?.strength_of_cashflow_sources_last_12_month
                                      }
                                      disabled={disable}
                                      onChange={onChangeCashFlow}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Strength of cashflow "
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.strength_of_cashflow_sources_last_12_month
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeCashFlowFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="2"
                                        key="> 125% of Operating Expenses"
                                      >
                                        Strong ({">"}125% of Operating Expenses)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="1.4"
                                        key="100%-125% of Operating Expenses"
                                      >
                                        Moderate (100%-125% of Operating
                                        Expenses)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="0.7"
                                        key="< 100% of Operating Expenses"
                                      >
                                        Weak ({"<"}100% of Operating Expenses)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {
                                      scores?.strength_of_cashflow_sources_last_12_month
                                    }
                                  </p>
                                </Col>
                              </>
                              {/* working capital requirements Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Ability to fund working capital requirements
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="working_capital"
                                  >
                                    <Input
                                      placeholder={
                                        values?.ability_to_fund_working_capital
                                      }
                                      disabled={disable}
                                      onChange={onChangeWorkingCapital}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Working Capital1 Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.ability_to_fund_working_capital
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeWorkingCapitalFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="2"
                                        key="Working Capital Ratio > 1.5"
                                      >
                                        Strong (Working Capital Ratio {">"}1.5)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="1.4"
                                        key="Working Capital Ratio 1.2-1.5"
                                      >
                                        Moderate (Working Capital Ratio 1.2-1.5)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="0.7"
                                        key="Working Capital Ratio <1.2"
                                      >
                                        Weak (Working Capital Ratio {"<"}1.2)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.ability_to_fund_working_capital}
                                  </p>
                                </Col>
                              </>
                              {/* Sophistication  Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Sophistication of business plan</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Business Plan Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.sophistication_of_business_plan
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeBusinessPlanFq}
                                    >
                                      <Option value="strong" data_value="2">
                                        Strong{" "}
                                      </Option>
                                      <Option value="moderate" data_value="1.4">
                                        Moderate
                                      </Option>
                                      <Option value="weak" data_value="0.7">
                                        Weak
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.sophistication_of_business_plan}
                                  </p>
                                </Col>
                              </>
                              {/* Operating history  Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Operating history and track record </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>4%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Operating  History"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.operating_history?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeOpHistoryFq}
                                    >
                                      <Option value="strong" data_value="4">
                                        Strong{" "}
                                      </Option>
                                      <Option value="moderate" data_value="2.6">
                                        Moderate
                                      </Option>
                                      <Option value="weak" data_value="1.6">
                                        Weak
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.operating_history}</p>
                                </Col>
                              </>
                              {/* hectares number Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Average number of hectares historically
                                    worked/booked per year
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>4%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="hectares"
                                  >
                                    <Input
                                      placeholder={
                                        values?.average_number_of_hectares
                                      }
                                      disabled={disable}
                                      onChange={onChangeHectares}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Average number of hectares"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.average_number_of_hectares
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeHectaresFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="4"
                                        key="3yr Annual Avg. >500 hectares"
                                      >
                                        Strong (3yr Annual Avg. {">"}500
                                        hectares)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2.6"
                                        key="2yr Annual Avg. >500 hectares"
                                      >
                                        Moderate (2yr Annual Avg. {">"}500
                                        hectares)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1.3"
                                        key="> 500 hectares in LTM only"
                                      >
                                        Weak ({">"}500 hectares in LTM only)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.average_number_of_hectares}</p>
                                </Col>
                              </>
                            </Row>
                          </div>
                        </Col>
                      </Card>
                    </Col>
                    {/* Transaction Structure  Card */}
                    <Col span={24}>
                      <Card className="criclebox ">
                        <Col span={23} className="transaction_header">
                          <p>Transaction Structure</p>
                        </Col>
                        <Divider />
                        <Col span={24}>
                          <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                              <TransactionHeaders />
                              {/* Contractual securityData */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Contractual security in place to secure
                                    asset in favor of the lender
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="contractual"
                                  >
                                    <Input
                                      placeholder={values?.contractual_security}
                                      disabled={disable}
                                      onChange={onChangeContractualSec}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Contractual securityFactor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.contractual_security?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeContractualSecFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="> 15% Restricted Cash Held at HT"
                                      >
                                        Strong ({">"}15% Restricted Cash Held at
                                        HT)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3.5"
                                        key="5-15% Restricted Cash Held at HT"
                                      >
                                        Moderate (5-15% Restricted Cash Held at
                                        HT)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="< 5% Restricted Cash Held at HT"
                                      >
                                        Weak ({"<"}5% Restricted Cash Held at
                                        HT)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.contractual_security}</p>
                                </Col>
                              </>
                              {/* Loan-to-value Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Loan-to-value</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="loan_value"
                                  >
                                    <Input
                                      placeholder={values?.loan_to_value}
                                      disabled={disable}
                                      onChange={onChangeLoanValue}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Loan-to-value Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.loan_to_value?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeLoanValueFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="< 80%"
                                      >
                                        Strong (LTV {"<"}80%)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="80-100%"
                                      >
                                        Moderate (LTV 80-100%)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="> 100%"
                                      >
                                        Weak (LTV {">"}100%)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.loan_to_value}</p>
                                </Col>
                              </>
                              {/* Form of payment Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Form of payment collection and visibility to
                                    HT
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Form of payment collection"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.form_of_payment_collection_and_visibility
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangePayCollectionFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="Payments Restricted to HT Wallet"
                                      >
                                        Strong (Payments Restricted to HT
                                        Wallet)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="HT Wallet & Off-Platform Payments"
                                      >
                                        Moderate(HT Wallet & Off-Platform
                                        Payments)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="Off-Platform Payments only"
                                      >
                                        Weak (Off-Platform Payments only)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {
                                      scores?.form_of_payment_collection_and_visibility
                                    }
                                  </p>
                                </Col>
                              </>
                              {/* excess payment Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Ability to capture excess payment included
                                    in loan construct
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="excess_payment"
                                  >
                                    <Input
                                      placeholder={
                                        values?.ability_to_capture_excess_payment
                                      }
                                      disabled={disable}
                                      onChange={onChangeExcessPayment}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Excess Payment Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.ability_to_capture_excess_payment
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeExcessPaymentFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="Ability to capture access payment at the sole discretion of HT"
                                      >
                                        Strong (Ability to capture access
                                        payment at the sole discretion of HT)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="ability to capture access payment upon breach of covenant"
                                      >
                                        Moderate (ability to capture access
                                        payment upon breach of covenant)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="absence of excess payment capture clause"
                                      >
                                        Weak (absence of excess payment capture
                                        clause)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.ability_to_capture_excess_payment}
                                  </p>
                                </Col>
                              </>
                              {/* Loan Tenor  Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Loan Tenor</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="Loan Tenor"
                                  >
                                    <Input
                                      placeholder={values?.loan_tenor}
                                      disabled={disable}
                                      onChange={onChangeLoanTenor}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Loan Tenor Factor Quality"
                                  >
                                    <Select
                                      placeholder={qualities?.loan_tenor?.text}
                                      disabled={disable}
                                      onChange={onChangeLoanTenorFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="2-3 years"
                                      >
                                        Strong (2-3 years){" "}
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="4-5 years"
                                      >
                                        Moderate (4-5 years)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="5 years"
                                      >
                                        Weak ({">"} 5 years)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.loan_tenor}</p>
                                </Col>
                              </>
                              {/* Social guarantees Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Social guarantees</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2.5%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="social guarantees"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.social_guarantees?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeSocialGuaranteesFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="2.5"
                                        key="5 strongly-rated written recommendations on record"
                                      >
                                        Strong (5 strongly-rated written
                                        recommendations on record){" "}
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="1.75"
                                        key="3-4 strongly-rated written recommendations on record"
                                      >
                                        Moderate (3-4 strongly-rated written
                                        recommendations on record)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="1-2 strongly-rated written recommendation(s) on record"
                                      >
                                        Weak (1-2 strongly-rated written
                                        recommendation(s) on record)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.social_guarantees}</p>
                                </Col>
                              </>
                              {/* Cross guarantee Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Cross guarantee and cross-collateralization
                                    (call default on multuple when 1 breaches)
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2.5%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Cross guarantee"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.cross_guarantee_and_cross
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeCrossGuaranteesFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="2.5"
                                        key="cross-collateralization with 2 or more existing PAYG borrowers"
                                      >
                                        Strong (cross-collateralization with 2
                                        or more existing PAYG borrowers)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="1.75"
                                        key="cross-collateralization with 1 other existing PAYG borrower"
                                      >
                                        Moderate (cross-collateralization with 1
                                        other existing PAYG borrower)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="No cross-collateralization"
                                      >
                                        Weak (No cross-collateralization)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.cross_guarantee_and_cross}</p>
                                </Col>
                              </>
                            </Row>
                          </div>
                        </Col>
                      </Card>
                    </Col>
                    {/* Asset Area & Plan Viability  Card */}
                    <Col span={24}>
                      <Card className="criclebox ">
                        <Col span={23} className="transaction_header">
                          <p>Asset Area & Plan Viability</p>
                        </Col>
                        <Divider />
                        <Col span={24}>
                          <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                              <TransactionHeaders />
                              {/* Size of serviceable land */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Size of serviceable land (hectares), market
                                    share and type of work in the area of
                                    operation
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Size of serviceable land"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.size_of_serviceable_land
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeServiceableLandFq}
                                    >
                                      <Option value="strong" data_value="3">
                                        Strong{" "}
                                      </Option>
                                      <Option value="moderate" data_value="2">
                                        Moderate{" "}
                                      </Option>
                                      <Option value="weak" data_value="1">
                                        Weak{" "}
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.size_of_serviceable_land}</p>
                                </Col>
                              </>
                              {/* Essentiality Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Essentiality of the crops covered </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Essentiality of the crops covered"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.essentiality_of_the_crops_covered
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeCropsCoveredFq}
                                    >
                                      <Option value="strong" data_value="2">
                                        Strong
                                      </Option>
                                      <Option value="moderate" data_value="1.4">
                                        Moderate
                                      </Option>
                                      <Option value="weak" data_value="0.7">
                                        Weak{" "}
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.essentiality_of_the_crops_covered}
                                  </p>
                                </Col>
                              </>
                              {/* Competition in the area of operation Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Competition in the area of operation</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Competition Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.competition_in_the_area?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeOpCompetitionFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="2"
                                        key="little competition"
                                      >
                                        Strong (little competition)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="1.4"
                                        key="moderate competition"
                                      >
                                        Moderate (moderate competition)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="0.7"
                                        key="little to no competition"
                                      >
                                        Weak (little to no competition)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.competition_in_the_area}</p>
                                </Col>
                              </>
                              {/* contractual relationship Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Nature of contractual relationship with
                                    customers
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>2%</p>
                                </Col>
                                <Col span={3}> </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="contractual relationship"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.nature_of_contractual_relationship_with_customers
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeCustomerRelFq}
                                    >
                                      <Option value="strong" data_value="2">
                                        Strong{" "}
                                      </Option>
                                      <Option value="moderate" data_value="1.4">
                                        Moderate{" "}
                                      </Option>
                                      <Option value="weak" data_value="0.7">
                                        Weak
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {
                                      scores?.nature_of_contractual_relationship_with_customers
                                    }
                                  </p>
                                </Col>
                              </>
                              {/* Technology  Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Technology - commercially proven or untested
                                    (within the targeted market)
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Technology Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.technology_commercially_proven_or_untested
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeTechnologyFq}
                                    >
                                      <Option value="strong" data_value="3">
                                        Strong
                                      </Option>
                                      <Option value="moderate" data_value="2">
                                        Moderate{" "}
                                      </Option>
                                      <Option value="weak" data_value="1">
                                        Weak
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {
                                      scores?.technology_commercially_proven_or_untested
                                    }
                                  </p>
                                </Col>
                              </>
                              {/* capital and maintenance expenditureData */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Required capital and maintenance expenditure
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="required_capital"
                                  >
                                    <Input
                                      placeholder={values?.required_capital}
                                      disabled={disable}
                                      onChange={onChangeRequiredCapital}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Required CapitalFactor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.required_capital?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeRequiredCapitalFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key="< 10% of new asset market value per annum"
                                      >
                                        Strong ({"<"}10% of new asset market
                                        value per annum){" "}
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="10-12% of new asset market value per annum"
                                      >
                                        Moderate (10-12% of new asset market
                                        value per annum)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key=">12% of new asset market value per annum"
                                      >
                                        Weak ({">"} 12% of new asset market
                                        value per annum)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.required_capital}</p>
                                </Col>
                              </>
                              {/* Tractor Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    How many days can tractor be idle without
                                    being able to make debt payments
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>10%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="numbe of days"
                                  >
                                    <Input
                                      placeholder={
                                        values?.how_many_days_can_tractor_be_idle
                                      }
                                      disabled={disable}
                                      onChange={onChangeIdleTractors}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="number of days"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.how_many_days_can_tractor_be_idle
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeIdleTractorsFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="10"
                                        key="> 315 days"
                                      >
                                        Strong ({">"}315 days)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="7"
                                        key="215-315"
                                      >
                                        Moderate (215-315)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="3"
                                        key="215 days"
                                      >
                                        Weak ({"<"}215 days)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.how_many_days_can_tractor_be_idle}
                                  </p>
                                </Col>
                              </>
                              {/* Route planning tool Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Based on route planning tool, payback period
                                    (in years)
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>5%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="route_planning_tool"
                                  >
                                    <Input
                                      placeholder={
                                        values?.based_on_route_planning_tool
                                      }
                                      disabled={disable}
                                      onChange={onChangeRouteTool}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="payback period"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.based_on_route_planning_tool
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeRouteToolFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="5"
                                        key="< 3.0 years"
                                      >
                                        Strong ({"<"}3.0 years)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3.4"
                                        key="3.0-5.0 years"
                                      >
                                        Moderate (3.0-5.0 years)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1.7"
                                        key="> 5.0 years"
                                      >
                                        Weak ({">"}5.0 years)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.based_on_route_planning_tool}</p>
                                </Col>
                              </>
                            </Row>
                          </div>
                        </Col>
                      </Card>
                    </Col>
                    {/* Strategic Opportunity Card */}
                    <Col span={24}>
                      <Card className="criclebox ">
                        <Col span={22} className="transaction_header">
                          <p>Strategic Opportunity</p>
                        </Col>
                        <Divider />
                        <Col span={24}>
                          <div className="number">
                            <Row align="middle" gutter={[24, 0]}>
                              <TransactionHeaders />
                              {/* Likelihood of expansion tData */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Likelihood of expansion to other HT services
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Likelihood of expansion"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.likelihood_of_expansion_to_other_ht_services
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeExtensionLikelihoodFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key="Existing Relationship or Being Onboarded"
                                      >
                                        Strong (Existing Relationship or Being
                                        Onboarded)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="Likely given expressed client interest"
                                      >
                                        Moderate (Likely given expressed client
                                        interest)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="Unsure or Not Likely"
                                      >
                                        Weak (Unsure or Not Likely)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {
                                      scores?.likelihood_of_expansion_to_other_ht_services
                                    }
                                  </p>
                                </Col>
                              </>
                              {/* Transaction Fees absolute Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>Transaction Fees (absolute $000)</p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="Transaction Fee"
                                  >
                                    <Input
                                      placeholder={values?.transaction_fee}
                                      disabled={disable}
                                      onChange={onChangeTransactionFee}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Transaction Fee  Factor Quality"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.transaction_fee?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeTransactionFeeFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key="> 5% of loan amount"
                                      >
                                        Strong ({">"}5% of loan amount)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="2-5% of loan amount"
                                      >
                                        Moderate (2-5% of loan amount)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="< 2% of loan amount"
                                      >
                                        Weak ({"<"}2% of loan amount)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.transaction_fee}</p>
                                </Col>
                              </>
                              {/* Transaction Fees as a percentage Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Transaction Fees as a percentage of funding
                                    from HT balance sheet
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>3%</p>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    className="username"
                                    name="Transaction Fees as a percentage"
                                  >
                                    <Input
                                      placeholder={
                                        values?.transaction_fees_as_a_percentage
                                      }
                                      disabled={disable}
                                      onChange={onChangeTransactionFeeP}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Transaction Fees as percentage"
                                  >
                                    <Select
                                      placeholder={
                                        qualities
                                          ?.transaction_fees_as_a_percentage
                                          ?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeTransactionFeePFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="3"
                                        key="> 5% of funded amount"
                                      >
                                        Strong ({">"}5% of funded amount)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="2"
                                        key="2-5% of funded amount"
                                      >
                                        Moderate (2-5% of funded amount)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="< 2% of funded amount"
                                      >
                                        Weak ({"<"}2% of funded amount)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>
                                    {scores?.transaction_fees_as_a_percentage}
                                  </p>
                                </Col>
                              </>
                              {/* Impact assessment Data */}
                              <>
                                <Col span={9} className="transaction_values">
                                  <p>
                                    Impact assessment (borrower gender,
                                    environmental friendliness of financed
                                    equipment)
                                  </p>
                                </Col>
                                <Col span={2} className="transaction_values">
                                  <p>6%</p>
                                </Col>
                                <Col span={3}></Col>
                                <Col span={7}>
                                  <Form.Item
                                    className="username"
                                    name="Impact assessment"
                                  >
                                    <Select
                                      placeholder={
                                        qualities?.impact_assessment?.text
                                      }
                                      disabled={disable}
                                      onChange={onChangeImpactAssessmentFq}
                                    >
                                      <Option
                                        value="strong"
                                        data_value="6"
                                        key="High Impact - Female Borrower"
                                      >
                                        Strong (High Impact - Female Borrower)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="Moderate Impact - Carbon Footprint Reduction"
                                      >
                                        Moderate (Moderate Impact - Carbon
                                        Footprint Reduction)
                                      </Option>
                                      <Option
                                        value="moderate"
                                        data_value="3"
                                        key="Moderate (Moderate Impact - Traditionally Unbanked"
                                      >
                                        Moderate (Moderate Impact -
                                        Traditionally Unbanked)
                                      </Option>
                                      <Option
                                        value="weak"
                                        data_value="1"
                                        key="Does not fall under outlined impact criteria"
                                      >
                                        Weak (Does not fall under outlined
                                        impact criteria)
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={3} className="transaction_values">
                                  <p>{scores?.impact_assessment}</p>
                                </Col>
                              </>
                            </Row>
                          </div>
                        </Col>
                      </Card>
                    </Col>

                    {/* Complete Form */}
                    <Col span={24}>
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col span={12}>
                            <Title level={5} style={{ color: "#FE471E" }}>
                              Transaction Score:{" "}
                            </Title>
                            <p className="amount_p">
                              {scores?.transaction_score}
                            </p>
                          </Col>
                          <Col span={8} offset={3}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{
                                background: "#FE471E",
                                width: "50%",
                                borderColor: "#FE471E",
                              }}
                            >
                              <p style={{ color: "#ffff" }}>SAVE</p>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Space>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditTransaction;
