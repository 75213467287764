import React, {useRef, useState} from 'react';
import {Row, Col, Typography, Layout, Form, Input, Button, message} from 'antd';
import {Link, Navigate} from 'react-router-dom';

import headerlogo from '../assets/images/ui-log.png';
import logo from '../assets/images/logo.png';
import axios from '../api/axios';
const SIGNUP_URL = '/v1/user/';
const {Title} = Typography;
const {Content} = Layout;

const SignUp = () => {
  const userRef = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');
  const [success, setSuccess] = useState(false);
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangePasswordConfirm = (e) => {
    const password_confirm = e.target.value;
    setPasswordConfirm(password_confirm);
  };
  const handleSubmit = async () => {
    if (password !== password_confirm) {
      message.warn("Passwords don't match", 5);
    } else if (password.length < 8 || password_confirm.length < 8) {
      message.warn('Password must be longer than 7 characters', 5);
    } else {
      try {
        await axios.post(SIGNUP_URL, JSON.stringify({email, password}), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          withCredentials: true,
        });
        setEmail('');
        setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          message.warn('No Server Response', 5);
        } else if (err.response?.status === 400) {
          message.warn('Missing Username or Password', 5);
        } else {
          message.warn('Sign Up Failed', 5);
        }
      }
    }
  };

  if (success) {
    return <Navigate to='/' />;
  }
  return (
    <>
      <Layout className='layout-default layout-signin' style={{background: '#ffff'}}>
        <Content className='signin'>
          <Row gutter={[24, 0]} justify='space-around'>
            <Col
              className='sign-img'
              style={{alignContent: 'center'}}
              xs={{span: 24}}
              lg={{span: 12}}
              md={{span: 12}}
            >
              <img src={headerlogo} alt='' />
            </Col>
            <Col
              style={{alignContent: 'center'}}
              xs={{span: 24, offset: 0}}
              lg={{span: 11, offset: 1}}
              md={{span: 12}}
            >
              <img src={logo} alt='' style={{width: '25%', marginLeft: '35%'}} />
              <Title level={5} style={{marginLeft: '30%'}}>
                Enter Details to get Registered
              </Title>
              <Form layout='vertical' className='row-col' onFinish={handleSubmit}>
                <Form.Item
                  className='username'
                  name='username'
                  id='username'
                  onChange={onChangeEmail}
                  value={email}
                  rules={[{required: true, message: 'Please input your email!'}, {type: 'email'}]}
                >
                  <Input ref={userRef} placeholder='Email' />
                </Form.Item>

                <Form.Item
                  className='username'
                  name='password'
                  id='password'
                  onChange={onChangePassword}
                  value={password}
                  rules={[{required: true, message: 'Please input your password!'}]}
                >
                  <Input.Password placeholder='Password' />
                </Form.Item>
                <Form.Item
                  className='username'
                  name='password_confirm'
                  id='password_confirm'
                  onChange={onChangePasswordConfirm}
                  value={password_confirm}
                  rules={[{required: true, message: 'Please confirm your password!'}]}
                >
                  <Input.Password placeholder='Confirm Password' />
                </Form.Item>
                <Form.Item>
                  <div className='header-col '>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{width: '100%', background: '#FE471E', borderColor: '#FE471E'}}
                      shape='round'
                    >
                      SIGN UP
                    </Button>
                  </div>
                </Form.Item>
                <p className='font-semibold text-muted'>
                  Already have an account?{' '}
                  <Link to='/' className='text-dark font-bold'>
                    Sign In here
                  </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SignUp;
