import {
  Row,
  Col,
  DatePicker,
  Input,
  Form,
  Select,
  Button,
  Card,
  message,
} from "antd";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";

const ADD_BIO_DATA = "/v1/borrower/";
const { Option } = Select;

const EditBioData = ({
  borrower,
  setDisable,
  disable,
  setBorrower,
  fetchSigleBorrower,
}) => {
  const token = window.localStorage.getItem("Bearer");
  const handleEdit = () => {
    setDisable(false);
  };
  const handleSubmit = async () => {
    const id = borrower?.borrower_id;
    const form_data = {
      first_name: borrower?.first_name,
      last_name: borrower?.last_name,
      gender: borrower?.gender,
      identity_number: borrower?.identity_number,
      date_of_birth: borrower?.date_of_birth,
      tax_number: borrower?.tax_number,
      physical_address: borrower?.physical_address,
      postal_address: borrower?.postal_address,
      nationality: borrower?.nationality,
      city_region: borrower?.city_region,
      country: borrower?.country,
      primary_email: borrower?.primary_email,
      secondary_email: borrower?.secondary_email,
      primary_phone_no: borrower?.primary_phone_no,
      secondary_phone_no: borrower?.secondary_phone_no,
    };
    try {
      await axios.patch(`${ADD_BIO_DATA}${id}`, JSON.stringify(form_data), {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        withCredentials: true,
      });
      fetchSigleBorrower();
      message.success("You have successfull changed borrower data", 5);
      setDisable(true);
    } catch (err) {
      message.warn("Somethingwent wrong.Try Again", 5);
    }
  };
  return (
    <Card className="criclebox ">
      <Form layout="vertical" className="row-col" onFinish={handleSubmit}>
        <Row span={24}>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="First Name"
                    label="First Name"
                  >
                    <Input
                      disabled={disable}
                      value={borrower?.first_name}
                      placeholder={borrower?.first_name}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, first_name: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Last Name"
                    label="Last Name"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.last_name}
                      value={borrower?.last_name}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, last_name: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="gender"
                    id="gender"
                    label="Gender"
                  >
                    <Select
                      disabled={disable}
                      placeholder={borrower?.gender}
                      onChange={(value) => {
                        setBorrower((pre) => {
                          return { ...pre, gender: value };
                        });
                      }}
                      allowClear
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Date of birth"
                    id="Date of birth"
                    label="Date of birth"
                  >
                    <DatePicker
                      disabled={disable}
                      placeholder={borrower?.date_of_birth}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setBorrower((pre) => {
                          return { ...pre, date_of_birth: dateString };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="National Id/Passport Number"
                    label="National Id/Passport Number"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.identity_number}
                      value={borrower?.identity_number}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, identity_number: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Tax Number"
                    label="KRA/FIRS PIN"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.tax_number}
                      value={borrower?.tax_number}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, tax_number: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Postal Address"
                    label="Postal Address"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.postal_address}
                      value={borrower?.postal_address}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, postal_address: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Physical address"
                    label="Physical address"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.physical_address}
                      value={borrower?.physical_address}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, physical_address: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="country"
                    label="Country"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.country}
                      value={borrower?.country}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, country: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="nationality"
                    label="Nationality"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.nationality}
                      value={borrower?.nationality}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, nationality: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name=" City/Region"
                    label=" City/Region"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.city_region}
                      value={borrower?.city_region}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, city_region: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Primary Email"
                    label="Primary Email"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.primary_email}
                      value={borrower?.primary_email}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, primary_email: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Secondary Email"
                    label="Secondary Email"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.secondary_email}
                      value={borrower?.secondary_email}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, secondary_email: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Primary Phone Number"
                    label="Primary Phone Number"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.primary_phone_no}
                      value={borrower?.primary_phone_no}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, primary_phone_no: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={22}>
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    name="Secondary Phone Number"
                    label="Secondary Phone Number"
                  >
                    <Input
                      disabled={disable}
                      placeholder={borrower?.secondary_phone_no}
                      value={borrower?.secondary_phone_no}
                      onChange={(e) => {
                        setBorrower((pre) => {
                          return { ...pre, secondary_phone_no: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} offset={2}>
                  {!disable ? (
                    <Link to="/dashboard">
                      {" "}
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          background: "#FE471E",
                          width: "70%",
                          borderColor: "#FE471E",
                        }}
                      >
                        <p style={{ color: "#ffff" }}>CANCEL</p>
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      onClick={handleEdit}
                      style={{
                        background: "#FE471E",
                        width: "90%",
                        borderColor: "#FE471E",
                      }}
                    >
                      <p style={{ color: "#ffff" }}>Edit</p>
                    </Button>
                  )}
                </Col>
                <Col span={8}>
                  {!disable && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        background: "#FE471E",
                        width: "50%",
                        borderColor: "#FE471E",
                      }}
                    >
                      <p style={{ color: "#ffff" }}>SAVE</p>
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EditBioData;
